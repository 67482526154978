/* WARNING: Omit `@font-face` (let Next.js handle it)

@font-face {
  font-family: 'icomoon';
  src: url('icomoon.eot?b5c9kn');
  src:
    url('icomoon.eot?b5c9kn#iefix') format('embedded-opentype'),
    url('icomoon.ttf?b5c9kn') format('truetype'),
    url('icomoon.woff?b5c9kn') format('woff'),
    url('icomoon.svg?b5c9kn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

*/

[class^='ss-'],
[class*=' ss-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: var(--next-font-icomoon, 'icomoon') !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ss-happy-filled-12:before {
  content: '\eb83';
}
.ss-happy-filled-16:before {
  content: '\eb84';
}
.ss-happy-filled-24:before {
  content: '\eb85';
}
.ss-happy-filled-32:before {
  content: '\eb86';
}
.ss-happy-filled-48:before {
  content: '\eb87';
}
.ss-happy-filled-64:before {
  content: '\eb88';
}
.ss-happy-outline-12:before {
  content: '\eb89';
}
.ss-happy-outline-16:before {
  content: '\eb8a';
}
.ss-happy-outline-24:before {
  content: '\eb8c';
}
.ss-happy-outline-32:before {
  content: '\eb8d';
}
.ss-happy-outline-48:before {
  content: '\eb8e';
}
.ss-happy-outline-64:before {
  content: '\eb8f';
}
.ss-neutral-filled-12:before {
  content: '\eb90';
}
.ss-neutral-filled-16:before {
  content: '\eb91';
}
.ss-neutral-filled-24:before {
  content: '\eb92';
}
.ss-neutral-filled-32:before {
  content: '\eb93';
}
.ss-neutral-filled-48:before {
  content: '\eb94';
}
.ss-neutral-filled-64:before {
  content: '\eb95';
}
.ss-neutral-outline-12:before {
  content: '\eb96';
}
.ss-neutral-outline-16:before {
  content: '\eb97';
}
.ss-neutral-outline-24:before {
  content: '\eb98';
}
.ss-neutral-outline-32:before {
  content: '\eb99';
}
.ss-neutral-outline-48:before {
  content: '\eb9a';
}
.ss-neutral-outline-64:before {
  content: '\eb9b';
}
.ss-sad-filled-12:before {
  content: '\eb9c';
}
.ss-sad-filled-16:before {
  content: '\eb9d';
}
.ss-sad-filled-24:before {
  content: '\eb9e';
}
.ss-sad-filled-32:before {
  content: '\eb9f';
}
.ss-sad-filled-48:before {
  content: '\eba0';
}
.ss-sad-filled-64:before {
  content: '\eba1';
}
.ss-sad-outline-12:before {
  content: '\eba2';
}
.ss-sad-outline-16:before {
  content: '\eba3';
}
.ss-sad-outline-24:before {
  content: '\eba4';
}
.ss-sad-outline-32:before {
  content: '\eba5';
}
.ss-sad-outline-48:before {
  content: '\eba6';
}
.ss-sad-outline-64:before {
  content: '\eba7';
}
.ss-lightning-12:before {
  content: '\eb35';
}
.ss-lightning-16:before {
  content: '\eb7c';
}
.ss-lightning-24:before {
  content: '\eb7f';
}
.ss-lightning-32:before {
  content: '\eb80';
}
.ss-lightning-48:before {
  content: '\eb81';
}
.ss-lightning-64:before {
  content: '\eb82';
}
.ss-trending-12:before {
  content: '\eb15';
}
.ss-trending-16:before {
  content: '\eb17';
}
.ss-trending-24:before {
  content: '\eb18';
}
.ss-trending-32:before {
  content: '\eb19';
}
.ss-trending-48:before {
  content: '\eb1f';
}
.ss-trending-64:before {
  content: '\eb33';
}
.ss-icon-thick-checkmark:before {
  content: '\eae7';
}
.ss-plumbing-12:before {
  content: '\e9d5';
}
.ss-plumbing-16:before {
  content: '\e9d8';
}
.ss-plumbing-24:before {
  content: '\e9d9';
}
.ss-plumbing-32:before {
  content: '\e9da';
}
.ss-plumbing-48:before {
  content: '\e9db';
}
.ss-plumbing-64:before {
  content: '\e9dc';
}
.ss-leverage-12:before {
  content: '\e9dd';
}
.ss-leverage-16:before {
  content: '\e9de';
}
.ss-leverage-24:before {
  content: '\e9fc';
}
.ss-leverage-32:before {
  content: '\e9fd';
}
.ss-leverage-48:before {
  content: '\e9fe';
}
.ss-leverage-64:before {
  content: '\e9ff';
}
.ss-heavy-lifting-12:before {
  content: '\ea00';
}
.ss-heavy-lifting-16:before {
  content: '\ea01';
}
.ss-heavy-lifting-24:before {
  content: '\ea02';
}
.ss-heavy-lifting-32:before {
  content: '\ea03';
}
.ss-heavy-lifting-48:before {
  content: '\ea04';
}
.ss-heavy-lifting-64:before {
  content: '\ea05';
}
.ss-snow-removal-12:before {
  content: '\ea06';
}
.ss-snow-removal-16:before {
  content: '\ea07';
}
.ss-snow-removal-24:before {
  content: '\ea08';
}
.ss-snow-removal-32:before {
  content: '\ea09';
}
.ss-snow-removal-48:before {
  content: '\ea0a';
}
.ss-snow-removal-64:before {
  content: '\ea0b';
}
.ss-supply-12:before {
  content: '\ea0c';
}
.ss-supply-16:before {
  content: '\ea0d';
}
.ss-supply-24:before {
  content: '\ea0e';
}
.ss-supply-32:before {
  content: '\ea0f';
}
.ss-supply-48:before {
  content: '\ea10';
}
.ss-supply-64:before {
  content: '\ea11';
}
.ss-okrs-12:before {
  content: '\ea12';
}
.ss-okrs-16:before {
  content: '\ea13';
}
.ss-okrs-24:before {
  content: '\ea14';
}
.ss-okrs-32:before {
  content: '\ea15';
}
.ss-okrs-48:before {
  content: '\ea18';
}
.ss-okrs-64:before {
  content: '\ea19';
}
.ss-rates-12:before {
  content: '\ea1a';
}
.ss-rates-16:before {
  content: '\ea1b';
}
.ss-rates-24:before {
  content: '\ea1d';
}
.ss-rates-32:before {
  content: '\ea1e';
}
.ss-rates-48:before {
  content: '\ea1f';
}
.ss-rates-64:before {
  content: '\ea20';
}
.ss-brand-awareness-12:before {
  content: '\ea21';
}
.ss-brand-awareness-16:before {
  content: '\ea22';
}
.ss-brand-awareness-24:before {
  content: '\ea23';
}
.ss-brand-awareness-32:before {
  content: '\ea24';
}
.ss-brand-awareness-48:before {
  content: '\ea25';
}
.ss-brand-awareness-64:before {
  content: '\ea26';
}
.ss-global-markets-12:before {
  content: '\ea27';
}
.ss-global-markets-16:before {
  content: '\ea28';
}
.ss-global-markets-24:before {
  content: '\ea29';
}
.ss-global-markets-32:before {
  content: '\ea2a';
}
.ss-global-markets-48:before {
  content: '\ea2b';
}
.ss-global-markets-64:before {
  content: '\ea2c';
}
.ss-platform-12:before {
  content: '\ea2d';
}
.ss-platform-16:before {
  content: '\ea2e';
}
.ss-platform-24:before {
  content: '\ea2f';
}
.ss-platform-32:before {
  content: '\ea30';
}
.ss-platform-48:before {
  content: '\ea31';
}
.ss-platform-64:before {
  content: '\ea32';
}
.ss-growth-12:before {
  content: '\ea33';
}
.ss-growth-16:before {
  content: '\ea34';
}
.ss-growth-24:before {
  content: '\ea36';
}
.ss-growth-32:before {
  content: '\ea37';
}
.ss-growth-48:before {
  content: '\ea38';
}
.ss-growth-64:before {
  content: '\ea39';
}
.ss-analytics-metrics-12:before {
  content: '\ea3a';
}
.ss-analytics-metrics-16:before {
  content: '\ea3b';
}
.ss-analytics-metrics-24:before {
  content: '\ea3c';
}
.ss-analytics-metrics-32:before {
  content: '\ea3d';
}
.ss-analytics-metrics-48:before {
  content: '\ea3e';
}
.ss-analytics-metrics-64:before {
  content: '\ea3f';
}
.ss-chart-12:before {
  content: '\ea40';
}
.ss-chart-16:before {
  content: '\ea41';
}
.ss-chart-24:before {
  content: '\ea42';
}
.ss-chart-32:before {
  content: '\ea43';
}
.ss-chart-48:before {
  content: '\ea44';
}
.ss-chart-64:before {
  content: '\ea45';
}
.ss-goals-12:before {
  content: '\ea46';
}
.ss-goals-16:before {
  content: '\ea47';
}
.ss-goals-24:before {
  content: '\ea48';
}
.ss-goals-32:before {
  content: '\ea49';
}
.ss-goals-48:before {
  content: '\ea4a';
}
.ss-goals-64:before {
  content: '\ea4b';
}
.ss-people-12:before {
  content: '\ea4c';
}
.ss-people-16:before {
  content: '\ea4d';
}
.ss-people-24:before {
  content: '\ea4e';
}
.ss-people-32:before {
  content: '\ea4f';
}
.ss-people-48:before {
  content: '\ea50';
}
.ss-people-64:before {
  content: '\ea51';
}
.ss-electrical-help-12:before {
  content: '\ea52';
}
.ss-electrical-help-16:before {
  content: '\ea53';
}
.ss-electrical-help-24:before {
  content: '\ea57';
}
.ss-electrical-help-32:before {
  content: '\ea58';
}
.ss-electrical-help-48:before {
  content: '\ea59';
}
.ss-electrical-help-64:before {
  content: '\ea5a';
}
.ss-delivery-12:before {
  content: '\ea5b';
}
.ss-delivery-16:before {
  content: '\ea5c';
}
.ss-delivery-24:before {
  content: '\ea5d';
}
.ss-delivery-32:before {
  content: '\ea5e';
}
.ss-delivery-48:before {
  content: '\ea5f';
}
.ss-delivery-64:before {
  content: '\ea60';
}
.ss-opportunities-12:before {
  content: '\ea61';
}
.ss-opportunities-16:before {
  content: '\ea62';
}
.ss-opportunities-24:before {
  content: '\ea63';
}
.ss-opportunities-32:before {
  content: '\ea64';
}
.ss-opportunities-48:before {
  content: '\ea65';
}
.ss-opportunities-64:before {
  content: '\ea66';
}
.ss-invoice-12:before {
  content: '\ea67';
}
.ss-invoice-16:before {
  content: '\ea68';
}
.ss-invoice-24:before {
  content: '\ea69';
}
.ss-invoice-32:before {
  content: '\ea6a';
}
.ss-invoice-48:before {
  content: '\ea6b';
}
.ss-invoice-64:before {
  content: '\ea6c';
}
.ss-reviews-12:before {
  content: '\ea6d';
}
.ss-reviews-16:before {
  content: '\ea6e';
}
.ss-reviews-24:before {
  content: '\ea6f';
}
.ss-reviews-32:before {
  content: '\ea70';
}
.ss-reviews-48:before {
  content: '\ea71';
}
.ss-reviews-64:before {
  content: '\ea72';
}
.ss-recommend-12:before {
  content: '\ea73';
}
.ss-recommend-16:before {
  content: '\ea74';
}
.ss-recommend-24:before {
  content: '\ea75';
}
.ss-recommend-32:before {
  content: '\ea76';
}
.ss-recommend-48:before {
  content: '\ea77';
}
.ss-recommend-64:before {
  content: '\ea78';
}
.ss-retention-12:before {
  content: '\ea79';
}
.ss-retention-16:before {
  content: '\ea7a';
}
.ss-retention-24:before {
  content: '\ea7b';
}
.ss-retention-32:before {
  content: '\ea7c';
}
.ss-retention-48:before {
  content: '\ea7d';
}
.ss-retention-64:before {
  content: '\ea7e';
}
.ss-skills-12:before {
  content: '\ea7f';
}
.ss-skills-16:before {
  content: '\ea80';
}
.ss-skills-24:before {
  content: '\ea81';
}
.ss-skills-32:before {
  content: '\ea82';
}
.ss-skills-48:before {
  content: '\ea83';
}
.ss-skills-64:before {
  content: '\ea84';
}
.ss-multi-category-skills-12:before {
  content: '\ea85';
}
.ss-multi-category-skills-16:before {
  content: '\ea86';
}
.ss-multi-category-skills-24:before {
  content: '\ea87';
}
.ss-multi-category-skills-32:before {
  content: '\ea88';
}
.ss-multi-category-skills-48:before {
  content: '\ea89';
}
.ss-multi-category-skills-64:before {
  content: '\ea8a';
}
.ss-personal-assistance-12:before {
  content: '\ea8b';
}
.ss-personal-assistance-16:before {
  content: '\ea8c';
}
.ss-personal-assistance-24:before {
  content: '\ea8d';
}
.ss-personal-assistance-32:before {
  content: '\ea8e';
}
.ss-personal-assistance-48:before {
  content: '\ea8f';
}
.ss-personal-assistance-64:before {
  content: '\ea90';
}
.ss-organization-12:before {
  content: '\ea91';
}
.ss-organization-16:before {
  content: '\ea92';
}
.ss-organization-24:before {
  content: '\ea93';
}
.ss-organization-32:before {
  content: '\ea94';
}
.ss-organization-48:before {
  content: '\ea95';
}
.ss-organization-64:before {
  content: '\ea96';
}
.ss-sort-12:before {
  content: '\ea97';
}
.ss-sort-16:before {
  content: '\ea98';
}
.ss-sort-24:before {
  content: '\ea99';
}
.ss-collection-12:before {
  content: '\ea9a';
}
.ss-collection-16:before {
  content: '\ea9b';
}
.ss-collection-24:before {
  content: '\ea9c';
}
.ss-instagram-12:before {
  content: '\ea9d';
}
.ss-instagram-16:before {
  content: '\eaa4';
}
.ss-instagram-24:before {
  content: '\eaa5';
}
.ss-instagram-32:before {
  content: '\eaa6';
}
.ss-instagram-48:before {
  content: '\eaa7';
}
.ss-instagram-64:before {
  content: '\eaa8';
}
.ss-facebook-12:before {
  content: '\eaa9';
}
.ss-facebook-16:before {
  content: '\eaaa';
}
.ss-facebook-24:before {
  content: '\eaab';
}
.ss-facebook-32:before {
  content: '\eaac';
}
.ss-facebook-48:before {
  content: '\eaad';
}
.ss-facebook-64:before {
  content: '\eaae';
}
.ss-tiktok-12:before {
  content: '\eaaf';
}
.ss-tiktok-16:before {
  content: '\eab0';
}
.ss-tiktok-24:before {
  content: '\eab1';
}
.ss-tiktok-32:before {
  content: '\eab2';
}
.ss-tiktok-48:before {
  content: '\eab3';
}
.ss-tiktok-64:before {
  content: '\eab4';
}
.ss-youtube-12:before {
  content: '\eab5';
}
.ss-youtube-16:before {
  content: '\eab6';
}
.ss-youtube-24:before {
  content: '\eab7';
}
.ss-youtube-32:before {
  content: '\eab8';
}
.ss-youtube-48:before {
  content: '\eab9';
}
.ss-youtube-64:before {
  content: '\eaba';
}
.ss-pinterest-12:before {
  content: '\eabb';
}
.ss-pinterest-16:before {
  content: '\eabc';
}
.ss-pinterest-24:before {
  content: '\eabd';
}
.ss-pinterest-32:before {
  content: '\eabe';
}
.ss-pinterest-48:before {
  content: '\eabf';
}
.ss-pinterest-64:before {
  content: '\eac0';
}
.ss-twitter-12:before {
  content: '\eac1';
}
.ss-twitter-16:before {
  content: '\eac2';
}
.ss-twitter-24:before {
  content: '\eac3';
}
.ss-twitter-32:before {
  content: '\eac4';
}
.ss-twitter-48:before {
  content: '\eac5';
}
.ss-twitter-64:before {
  content: '\eac6';
}
.ss-language-globe-12:before {
  content: '\eac7';
}
.ss-language-globe-48:before {
  content: '\eac8';
}
.ss-language-globe-16:before {
  content: '\eac9';
}
.ss-language-globe-24:before {
  content: '\eaca';
}
.ss-language-globe-32:before {
  content: '\eacb';
}
.ss-language-globe-64:before {
  content: '\eacc';
}
.ss-thumbs-12-down:before {
  content: '\eacd';
}
.ss-thumbs-12-up:before {
  content: '\eace';
}
.ss-thumbs-48-down:before {
  content: '\eacf';
}
.ss-thumbs-48-up:before {
  content: '\ead0';
}
.ss-thumbs-16-down:before {
  content: '\ead1';
}
.ss-thumbs-16-up:before {
  content: '\ead2';
}
.ss-thumbs-24-down:before {
  content: '\ead3';
}
.ss-thumbs-24-up:before {
  content: '\ead4';
}
.ss-thumbs-32-down:before {
  content: '\ead5';
}
.ss-thumbs-32-up:before {
  content: '\ead6';
}
.ss-thumbs-64-down:before {
  content: '\ead7';
}
.ss-thumbs-64-up:before {
  content: '\ead8';
}
.ss-tag-12:before {
  content: '\ead9';
}
.ss-tag-48:before {
  content: '\eada';
}
.ss-tag-16:before {
  content: '\eadb';
}
.ss-tag-24:before {
  content: '\eadc';
}
.ss-tag-32:before {
  content: '\eadd';
}
.ss-tag-64:before {
  content: '\eade';
}
.ss-pause-12:before {
  content: '\eadf';
}
.ss-pause-48:before {
  content: '\eae0';
}
.ss-pause-16:before {
  content: '\eae1';
}
.ss-pause-24:before {
  content: '\eae2';
}
.ss-pause-32:before {
  content: '\eae3';
}
.ss-pause-64:before {
  content: '\eae4';
}
.ss-logout-12:before {
  content: '\eae5';
}
.ss-logout-48:before {
  content: '\eae6';
}
.ss-logout-16:before {
  content: '\eae8';
}
.ss-logout-24:before {
  content: '\eae9';
}
.ss-logout-32:before {
  content: '\eaea';
}
.ss-logout-64:before {
  content: '\eaeb';
}
.ss-subtract-12:before {
  content: '\eaec';
}
.ss-subtract-48:before {
  content: '\eaed';
}
.ss-subtract-16:before {
  content: '\eaee';
}
.ss-subtract-24:before {
  content: '\eaef';
}
.ss-subtract-32:before {
  content: '\eaf0';
}
.ss-subtract-64:before {
  content: '\eaf1';
}
.ss-gear-12:before {
  content: '\eaf2';
}
.ss-gear-48:before {
  content: '\eaf3';
}
.ss-gear-16:before {
  content: '\eaf4';
}
.ss-gear-24:before {
  content: '\eaf5';
}
.ss-gear-32:before {
  content: '\eaf6';
}
.ss-gear-64:before {
  content: '\eaf7';
}
.ss-ladder-12:before {
  content: '\eaf8';
}
.ss-ladder-48:before {
  content: '\eaf9';
}
.ss-ladder-16:before {
  content: '\eafa';
}
.ss-ladder-24:before {
  content: '\eafb';
}
.ss-ladder-32:before {
  content: '\eafc';
}
.ss-ladder-64:before {
  content: '\eafd';
}
.ss-van-12:before {
  content: '\eafe';
}
.ss-van-48:before {
  content: '\eaff';
}
.ss-van-16:before {
  content: '\eb00';
}
.ss-van-24:before {
  content: '\eb01';
}
.ss-van-32:before {
  content: '\eb02';
}
.ss-van-64:before {
  content: '\eb03';
}
.ss-bike-12:before {
  content: '\eb04';
}
.ss-bike-48:before {
  content: '\eb05';
}
.ss-bike-16:before {
  content: '\eb06';
}
.ss-bike-24:before {
  content: '\eb07';
}
.ss-bike-32:before {
  content: '\eb08';
}
.ss-bike-64:before {
  content: '\eb09';
}
.ss-water-drop-12:before {
  content: '\eb0a';
}
.ss-water-drop-48:before {
  content: '\eb0b';
}
.ss-water-drop-16:before {
  content: '\eb0c';
}
.ss-water-drop-24:before {
  content: '\eb0d';
}
.ss-water-drop-32:before {
  content: '\eb0e';
}
.ss-water-drop-64:before {
  content: '\eb0f';
}
.ss-lawn-mower-12:before {
  content: '\eb10';
}
.ss-lawn-mower-48:before {
  content: '\eb11';
}
.ss-lawn-mower-16:before {
  content: '\eb12';
}
.ss-lawn-mower-24:before {
  content: '\eb13';
}
.ss-lawn-mower-32:before {
  content: '\eb14';
}
.ss-lawn-mower-64:before {
  content: '\eb16';
}
.ss-eco-globe-12:before {
  content: '\eb1a';
}
.ss-eco-globe-48:before {
  content: '\eb1b';
}
.ss-eco-globe-16:before {
  content: '\eb1c';
}
.ss-eco-globe-24:before {
  content: '\eb1d';
}
.ss-eco-globe-32:before {
  content: '\eb1e';
}
.ss-dolly-12:before {
  content: '\eb20';
}
.ss-dolly-48:before {
  content: '\eb21';
}
.ss-dolly-16:before {
  content: '\eb22';
}
.ss-dolly-24:before {
  content: '\eb23';
}
.ss-dolly-32:before {
  content: '\eb24';
}
.ss-dolly-64:before {
  content: '\eb25';
}
.ss-vacuum-12:before {
  content: '\eb26';
}
.ss-vacuum-48:before {
  content: '\eb27';
}
.ss-vacuum-16:before {
  content: '\eb28';
}
.ss-vacuum-24:before {
  content: '\eb29';
}
.ss-vacuum-32:before {
  content: '\eb2a';
}
.ss-vacuum-64:before {
  content: '\eb2b';
}
.ss-hand-12:before {
  content: '\eb2c';
}
.ss-hand-16:before {
  content: '\eb30';
}
.ss-hand-64:before {
  content: '\eb31';
}
.ss-hand-48:before {
  content: '\eb32';
}
.ss-hand-32:before {
  content: '\eb34';
}
.ss-hand-24:before {
  content: '\eb36';
}
.ss-trophy-64:before {
  content: '\eb37';
}
.ss-trophy-48:before {
  content: '\eb38';
}
.ss-trophy-32:before {
  content: '\eb39';
}
.ss-trophy-24:before {
  content: '\eb3a';
}
.ss-trophy-16:before {
  content: '\eb3b';
}
.ss-trophy-12:before {
  content: '\eb3c';
}
.ss-mind-12:before {
  content: '\eb3d';
}
.ss-mind-16:before {
  content: '\eb3e';
}
.ss-mind-24:before {
  content: '\eb3f';
}
.ss-mind-48:before {
  content: '\eb40';
}
.ss-mind-32:before {
  content: '\eb41';
}
.ss-mind-64:before {
  content: '\eb42';
}
.ss-badge-48-checked:before {
  content: '\eb43';
}
.ss-badge-12-checked:before {
  content: '\eb44';
}
.ss-badge-16-checked:before {
  content: '\eb45';
}
.ss-badge-24-checked:before {
  content: '\eb46';
}
.ss-badge-32-checked:before {
  content: '\eb47';
}
.ss-badge-64-checked:before {
  content: '\eb48';
}
.ss-headset-48:before {
  content: '\eb49';
}
.ss-headset-12:before {
  content: '\eb4a';
}
.ss-headset-16:before {
  content: '\eb4b';
}
.ss-headset-24:before {
  content: '\eb4c';
}
.ss-headset-32:before {
  content: '\eb4d';
}
.ss-headset-64:before {
  content: '\eb4e';
}
.ss-cart-12:before {
  content: '\eb4f';
}
.ss-cart-48:before {
  content: '\eb50';
}
.ss-cart-16:before {
  content: '\eb51';
}
.ss-cart-24:before {
  content: '\eb52';
}
.ss-cart-32:before {
  content: '\eb53';
}
.ss-cart-64:before {
  content: '\eb54';
}
.ss-armchair-12:before {
  content: '\eb55';
}
.ss-armchair-48:before {
  content: '\eb56';
}
.ss-armchair-16:before {
  content: '\eb57';
}
.ss-armchair-24:before {
  content: '\eb58';
}
.ss-armchair-32:before {
  content: '\eb59';
}
.ss-armchair-64:before {
  content: '\eb5a';
}
.ss-document-12:before {
  content: '\eb5b';
}
.ss-document-48:before {
  content: '\eb5c';
}
.ss-document-16:before {
  content: '\eb5d';
}
.ss-document-24:before {
  content: '\eb5e';
}
.ss-document-32:before {
  content: '\eb5f';
}
.ss-document-64:before {
  content: '\eb60';
}
.ss-graph-64:before {
  content: '\eb67';
}
.ss-graph-48:before {
  content: '\eb68';
}
.ss-graph-32:before {
  content: '\eb69';
}
.ss-graph-24:before {
  content: '\eb6a';
}
.ss-graph-12:before {
  content: '\eb6b';
}
.ss-graph-16:before {
  content: '\eb6c';
}
.ss-nuts-64:before {
  content: '\eb6d';
}
.ss-nuts-48:before {
  content: '\eb6e';
}
.ss-nuts-32:before {
  content: '\eb6f';
}
.ss-nuts-24:before {
  content: '\eb70';
}
.ss-nuts-12:before {
  content: '\eb71';
}
.ss-nuts-16:before {
  content: '\eb72';
}
.ss-paint-roller-64:before {
  content: '\eb73';
}
.ss-paint-roller-48:before {
  content: '\eb74';
}
.ss-paint-roller-32:before {
  content: '\eb75';
}
.ss-paint-roller-24:before {
  content: '\eb76';
}
.ss-paint-roller-12:before {
  content: '\eb77';
}
.ss-paint-roller-16:before {
  content: '\eb78';
}
.ss-drill-12:before {
  content: '\eb79';
}
.ss-drill-16:before {
  content: '\eb7a';
}
.ss-drill-24:before {
  content: '\eb7b';
}
.ss-drill-64:before {
  content: '\eb7d';
}
.ss-drill-48:before {
  content: '\eb7e';
}
.ss-drill-32:before {
  content: '\ebb7';
}
.ss-measure-16:before {
  content: '\ebb8';
}
.ss-measure-24:before {
  content: '\ebb9';
}
.ss-measure-64:before {
  content: '\ebba';
}
.ss-measure-48:before {
  content: '\ebbb';
}
.ss-measure-32:before {
  content: '\ebbc';
}
.ss-ruler-12:before {
  content: '\ebbd';
}
.ss-ruler-16:before {
  content: '\ebbe';
}
.ss-ruler-24:before {
  content: '\ebbf';
}
.ss-ruler-64:before {
  content: '\ebc0';
}
.ss-ruler-48:before {
  content: '\ebc1';
}
.ss-ruler-32:before {
  content: '\ebc2';
}
.ss-evening-12:before {
  content: '\ebc3';
}
.ss-evening-16:before {
  content: '\ebc4';
}
.ss-evening-24:before {
  content: '\ebcc';
}
.ss-evening-64:before {
  content: '\ebcd';
}
.ss-evening-48:before {
  content: '\ebce';
}
.ss-evening-32:before {
  content: '\ebcf';
}
.ss-afternoon-12:before {
  content: '\ebd2';
}
.ss-afternoon-16:before {
  content: '\ebdb';
}
.ss-afternoon-24:before {
  content: '\ebe4';
}
.ss-afternoon-64:before {
  content: '\ebe8';
}
.ss-afternoon-48:before {
  content: '\ebe9';
}
.ss-afternoon-32:before {
  content: '\ebea';
}
.ss-morning-12:before {
  content: '\ebeb';
}
.ss-morning-16:before {
  content: '\ebec';
}
.ss-morning-24:before {
  content: '\ebed';
}
.ss-morning-64:before {
  content: '\ebee';
}
.ss-morning-48:before {
  content: '\ebef';
}
.ss-morning-32:before {
  content: '\ebf0';
}
.ss-any-time-12:before {
  content: '\ebf1';
}
.ss-any-time-16:before {
  content: '\ebf2';
}
.ss-any-time-24:before {
  content: '\ebf3';
}
.ss-any-time-64:before {
  content: '\ebf4';
}
.ss-any-time-48:before {
  content: '\ebf5';
}
.ss-any-time-32:before {
  content: '\ebf6';
}
.ss-tools-12:before {
  content: '\ebf7';
}
.ss-tools-16:before {
  content: '\ebf8';
}
.ss-tools-24:before {
  content: '\ebf9';
}
.ss-tools-64:before {
  content: '\ebfa';
}
.ss-tools-48:before {
  content: '\ebfb';
}
.ss-tools-32:before {
  content: '\ebfc';
}
.ss-truck-12:before {
  content: '\ebfd';
}
.ss-truck-16:before {
  content: '\ebfe';
}
.ss-truck-24:before {
  content: '\ebff';
}
.ss-truck-64:before {
  content: '\ec00';
}
.ss-truck-48:before {
  content: '\ec01';
}
.ss-truck-32:before {
  content: '\ec02';
}
.ss-lightbulb-12:before {
  content: '\ec03';
}
.ss-lightbulb-16:before {
  content: '\ec04';
}
.ss-lightbulb-24:before {
  content: '\ec05';
}
.ss-lightbulb-48:before {
  content: '\ec06';
}
.ss-lightbulb-32:before {
  content: '\ec07';
}
.ss-lightbulb-64:before {
  content: '\ec08';
}
.ss-pulse-12:before {
  content: '\ec09';
}
.ss-pulse-24:before {
  content: '\ec0a';
}
.ss-pulse-16:before {
  content: '\ec0b';
}
.ss-pulse-48:before {
  content: '\ec0c';
}
.ss-pulse-32:before {
  content: '\ec0d';
}
.ss-pulse-64:before {
  content: '\ec0e';
}
.ss-road-48:before {
  content: '\ec0f';
}
.ss-road-12:before {
  content: '\ec10';
}
.ss-road-16:before {
  content: '\ec11';
}
.ss-road-24:before {
  content: '\ec12';
}
.ss-road-32:before {
  content: '\ec13';
}
.ss-road-64:before {
  content: '\ec14';
}
.ss-first-aid-bag-12:before {
  content: '\ec15';
}
.ss-first-aid-bag-16:before {
  content: '\ec16';
}
.ss-first-aid-bag-48:before {
  content: '\ec17';
}
.ss-first-aid-bag-24:before {
  content: '\ec18';
}
.ss-first-aid-bag-32:before {
  content: '\ec19';
}
.ss-first-aid-bag-64:before {
  content: '\ec1a';
}
.ss-heartbeat-48:before {
  content: '\ec1b';
}
.ss-heartbeat-32:before {
  content: '\ec1c';
}
.ss-heartbeat-12:before {
  content: '\ec1d';
}
.ss-heartbeat-16:before {
  content: '\ec1e';
}
.ss-heartbeat-24:before {
  content: '\ec1f';
}
.ss-heartbeat-64:before {
  content: '\ec20';
}
.ss-hammer-48:before {
  content: '\ec21';
}
.ss-hammer-12:before {
  content: '\ec22';
}
.ss-hammer-16:before {
  content: '\ec23';
}
.ss-hammer-24:before {
  content: '\ec24';
}
.ss-hammer-32:before {
  content: '\ec25';
}
.ss-hammer-64:before {
  content: '\ec26';
}
.ss-broom-12:before {
  content: '\ec27';
}
.ss-broom-48:before {
  content: '\ec28';
}
.ss-broom-16:before {
  content: '\ec29';
}
.ss-broom-24:before {
  content: '\ec2a';
}
.ss-broom-32:before {
  content: '\ec2b';
}
.ss-broom-64:before {
  content: '\ec2c';
}
.ss-tree-12:before {
  content: '\ec2d';
}
.ss-tree-48:before {
  content: '\ec2e';
}
.ss-tree-16:before {
  content: '\ec2f';
}
.ss-tree-24:before {
  content: '\ec30';
}
.ss-tree-32:before {
  content: '\ec31';
}
.ss-tree-64:before {
  content: '\ec32';
}
.ss-carriage-12:before {
  content: '\ec33';
}
.ss-carriage-48:before {
  content: '\ec34';
}
.ss-carriage-16:before {
  content: '\ec35';
}
.ss-carriage-24:before {
  content: '\ec36';
}
.ss-carriage-32:before {
  content: '\ec37';
}
.ss-carriage-64:before {
  content: '\ec38';
}
.ss-trophy-outline-12:before {
  content: '\ec39';
}
.ss-trophy-outline-48:before {
  content: '\ec3a';
}
.ss-trophy-outline-16:before {
  content: '\ec3b';
}
.ss-trophy-outline-24:before {
  content: '\ec3c';
}
.ss-trophy-outline-32:before {
  content: '\ec3d';
}
.ss-trophy-outline-64:before {
  content: '\ec3e';
}
.ss-delete-12:before {
  content: '\ec3f';
}
.ss-delete-16:before {
  content: '\ec40';
}
.ss-delete-64:before {
  content: '\ec41';
}
.ss-delete-48:before {
  content: '\ec42';
}
.ss-delete-32:before {
  content: '\ec43';
}
.ss-delete-24:before {
  content: '\ec44';
}
.ss-note-12:before {
  content: '\ec45';
}
.ss-note-16:before {
  content: '\ec46';
}
.ss-note-64:before {
  content: '\ec47';
}
.ss-note-48:before {
  content: '\ec48';
}
.ss-note-32:before {
  content: '\ec49';
}
.ss-note-24:before {
  content: '\ec4a';
}
.ss-rebook-12:before {
  content: '\ec4b';
}
.ss-rebook-16:before {
  content: '\ec4c';
}
.ss-rebook-64:before {
  content: '\ec4d';
}
.ss-rebook-48:before {
  content: '\ec4e';
}
.ss-rebook-32:before {
  content: '\ec4f';
}
.ss-rebook-24:before {
  content: '\ec50';
}
.ss-share-12:before {
  content: '\ec51';
}
.ss-share-16:before {
  content: '\ec52';
}
.ss-share-64:before {
  content: '\ec53';
}
.ss-share-48:before {
  content: '\ec54';
}
.ss-share-32:before {
  content: '\ec55';
}
.ss-share-24:before {
  content: '\ec56';
}
.ss-car-12:before {
  content: '\ec57';
}
.ss-car-16:before {
  content: '\ec58';
}
.ss-car-24:before {
  content: '\ec59';
}
.ss-car-64:before {
  content: '\ec5a';
}
.ss-car-48:before {
  content: '\ec5b';
}
.ss-car-32:before {
  content: '\ec5c';
}
.ss-receipt-12:before {
  content: '\ec5d';
}
.ss-receipt-16:before {
  content: '\ec5e';
}
.ss-receipt-64:before {
  content: '\ec5f';
}
.ss-receipt-48:before {
  content: '\ec60';
}
.ss-receipt-32:before {
  content: '\ec61';
}
.ss-receipt-24:before {
  content: '\ec62';
}
.ss-warning-12:before {
  content: '\ec63';
}
.ss-warning-16:before {
  content: '\ec64';
}
.ss-warning-64:before {
  content: '\ec65';
}
.ss-warning-48:before {
  content: '\ec66';
}
.ss-warning-32:before {
  content: '\ec67';
}
.ss-warning-24:before {
  content: '\ec68';
}
.ss-pending-12:before {
  content: '\ec6a';
}
.ss-pending-16:before {
  content: '\ec6b';
}
.ss-pending-64:before {
  content: '\ec6c';
}
.ss-pending-48:before {
  content: '\ec6d';
}
.ss-pending-32:before {
  content: '\ec6e';
}
.ss-pending-24:before {
  content: '\ec6f';
}
.ss-recurring-12:before {
  content: '\ec70';
}
.ss-recurring-16:before {
  content: '\ec71';
}
.ss-recurring-64:before {
  content: '\ec72';
}
.ss-recurring-48:before {
  content: '\ec73';
}
.ss-recurring-32:before {
  content: '\ec74';
}
.ss-recurring-24:before {
  content: '\ec75';
}
.ss-dollar-12:before {
  content: '\ec76';
}
.ss-dollar-16:before {
  content: '\ec77';
}
.ss-dollar-64:before {
  content: '\ec78';
}
.ss-dollar-48:before {
  content: '\ec79';
}
.ss-dollar-32:before {
  content: '\ec7a';
}
.ss-dollar-24:before {
  content: '\ec7b';
}
.ss-overflow-12:before {
  content: '\ec7c';
}
.ss-overflow-16:before {
  content: '\ec7d';
}
.ss-overflow-64:before {
  content: '\ec7e';
}
.ss-overflow-48:before {
  content: '\ec7f';
}
.ss-overflow-32:before {
  content: '\ec80';
}
.ss-overflow-24:before {
  content: '\ec81';
}
.ss-document-search-12:before {
  content: '\ec82';
}
.ss-document-search-16:before {
  content: '\ec83';
}
.ss-document-search-64:before {
  content: '\ec84';
}
.ss-document-search-48:before {
  content: '\ec85';
}
.ss-document-search-32:before {
  content: '\ec86';
}
.ss-document-search-24:before {
  content: '\ec87';
}
.ss-phone-12:before {
  content: '\ec88';
}
.ss-phone-16:before {
  content: '\ec89';
}
.ss-phone-64:before {
  content: '\ec8a';
}
.ss-phone-48:before {
  content: '\ec8b';
}
.ss-phone-32:before {
  content: '\ec8c';
}
.ss-phone-24:before {
  content: '\ec8d';
}
.ss-card-12:before {
  content: '\ec8e';
}
.ss-card-16:before {
  content: '\ec8f';
}
.ss-card-64:before {
  content: '\ec90';
}
.ss-card-48:before {
  content: '\ec91';
}
.ss-card-32:before {
  content: '\ec92';
}
.ss-card-24:before {
  content: '\ec93';
}
.ss-flag-12:before {
  content: '\ec94';
}
.ss-flag-16:before {
  content: '\ec95';
}
.ss-flag-24:before {
  content: '\ec96';
}
.ss-flag-48:before {
  content: '\ec97';
}
.ss-flag-32:before {
  content: '\ec98';
}
.ss-flag-64:before {
  content: '\ec99';
}
.ss-sparkle-12:before {
  content: '\ec9a';
}
.ss-sparkle-16:before {
  content: '\ec9b';
}
.ss-sparkle-24:before {
  content: '\ec9c';
}
.ss-sparkle-32:before {
  content: '\ec9d';
}
.ss-sparkle-48:before {
  content: '\ec9e';
}
.ss-sparkle-64:before {
  content: '\ec9f';
}
.ss-swipe-right-48:before {
  content: '\eca0';
}
.ss-swipe-right-12:before {
  content: '\eca1';
}
.ss-swipe-right-16:before {
  content: '\eca2';
}
.ss-swipe-right-24:before {
  content: '\eca3';
}
.ss-swipe-right-32:before {
  content: '\eca4';
}
.ss-swipe-right-64:before {
  content: '\eca5';
}
.ss-swipe-left-12:before {
  content: '\eca6';
}
.ss-swipe-left-48:before {
  content: '\eca7';
}
.ss-swipe-left-16:before {
  content: '\eca8';
}
.ss-swipe-left-24:before {
  content: '\eca9';
}
.ss-swipe-left-32:before {
  content: '\ecaa';
}
.ss-swipe-left-64:before {
  content: '\ecab';
}
.ss-profile-badge-12:before {
  content: '\ecac';
}
.ss-profile-badge-48:before {
  content: '\ecad';
}
.ss-profile-badge-16:before {
  content: '\ecae';
}
.ss-profile-badge-24:before {
  content: '\ecaf';
}
.ss-profile-badge-32:before {
  content: '\ecb0';
}
.ss-profile-badge-64:before {
  content: '\ecb1';
}
.ss-profile-edit-12:before {
  content: '\ecb2';
}
.ss-profile-edit-48:before {
  content: '\ecb3';
}
.ss-profile-edit-16:before {
  content: '\ecb4';
}
.ss-profile-edit-24:before {
  content: '\ecb5';
}
.ss-profile-edit-32:before {
  content: '\ecb6';
}
.ss-profile-edit-64:before {
  content: '\ecb7';
}
.ss-credit-card-locked-12:before {
  content: '\ecb8';
}
.ss-credit-card-locked-48:before {
  content: '\ecb9';
}
.ss-credit-card-locked-16:before {
  content: '\ecba';
}
.ss-credit-card-locked-24:before {
  content: '\ecbb';
}
.ss-credit-card-locked-32:before {
  content: '\ecbc';
}
.ss-credit-card-locked-64:before {
  content: '\ecbd';
}
.ss-credit-card-12:before {
  content: '\ecbe';
}
.ss-credit-card-48:before {
  content: '\ecbf';
}
.ss-credit-card-16:before {
  content: '\ecc0';
}
.ss-credit-card-24:before {
  content: '\ecc1';
}
.ss-credit-card-32:before {
  content: '\ecc2';
}
.ss-credit-card-64:before {
  content: '\ecc3';
}
.ss-lock-12:before {
  content: '\ecc4';
}
.ss-lock-48:before {
  content: '\ecc5';
}
.ss-lock-16:before {
  content: '\ecc6';
}
.ss-lock-24:before {
  content: '\ecc7';
}
.ss-lock-32:before {
  content: '\ecc8';
}
.ss-lock-64:before {
  content: '\ecc9';
}
.ss-privacy-12:before {
  content: '\ecca';
}
.ss-privacy-48:before {
  content: '\eccb';
}
.ss-privacy-16:before {
  content: '\eccc';
}
.ss-privacy-24:before {
  content: '\eccd';
}
.ss-privacy-32:before {
  content: '\ecce';
}
.ss-privacy-64:before {
  content: '\eccf';
}
.ss-hide-12:before {
  content: '\ecd0';
}
.ss-hide-48:before {
  content: '\ecd1';
}
.ss-hide-16:before {
  content: '\ecd2';
}
.ss-hide-24:before {
  content: '\ecd3';
}
.ss-hide-32:before {
  content: '\ecd4';
}
.ss-hide-64:before {
  content: '\ecd5';
}
.ss-view-12:before {
  content: '\ecd6';
}
.ss-view-48:before {
  content: '\ecd7';
}
.ss-view-16:before {
  content: '\ecd8';
}
.ss-view-24:before {
  content: '\ecd9';
}
.ss-view-32:before {
  content: '\ecda';
}
.ss-view-64:before {
  content: '\ecdb';
}
.ss-edit-12:before {
  content: '\ece2';
}
.ss-edit-64:before {
  content: '\ece3';
}
.ss-edit-48:before {
  content: '\ece4';
}
.ss-edit-32:before {
  content: '\ece5';
}
.ss-edit-24:before {
  content: '\ece6';
}
.ss-edit-16:before {
  content: '\ece7';
}
.ss-user-12:before {
  content: '\ece8';
}
.ss-user-16:before {
  content: '\ece9';
}
.ss-user-64:before {
  content: '\ecea';
}
.ss-user-48:before {
  content: '\eceb';
}
.ss-user-32:before {
  content: '\ecec';
}
.ss-user-24:before {
  content: '\eced';
}
.ss-help-64:before {
  content: '\ecee';
}
.ss-help-48:before {
  content: '\ecef';
}
.ss-help-32:before {
  content: '\ecf0';
}
.ss-help-24:before {
  content: '\ecf1';
}
.ss-help-16:before {
  content: '\ecf2';
}
.ss-help-12:before {
  content: '\ecf3';
}
.ss-play-64:before {
  content: '\ecf4';
}
.ss-play-48:before {
  content: '\ecf5';
}
.ss-play-32:before {
  content: '\ecf6';
}
.ss-play-24:before {
  content: '\ecf7';
}
.ss-play-16:before {
  content: '\ecf8';
}
.ss-play-12:before {
  content: '\ecf9';
}
.ss-video-64:before {
  content: '\ecfa';
}
.ss-video-48:before {
  content: '\ecfb';
}
.ss-video-32:before {
  content: '\ecfc';
}
.ss-video-24:before {
  content: '\ecfd';
}
.ss-video-16:before {
  content: '\ecfe';
}
.ss-video-12:before {
  content: '\ecff';
}
.ss-photo-64:before {
  content: '\ed00';
}
.ss-photo-48:before {
  content: '\ed01';
}
.ss-photo-32:before {
  content: '\ed02';
}
.ss-photo-24:before {
  content: '\ed03';
}
.ss-photo-16:before {
  content: '\ed04';
}
.ss-photo-12:before {
  content: '\ed05';
}
.ss-gps-64:before {
  content: '\ed06';
}
.ss-gps-48:before {
  content: '\ed07';
}
.ss-gps-32:before {
  content: '\ed08';
}
.ss-gps-24:before {
  content: '\ed09';
}
.ss-gps-16:before {
  content: '\ed0a';
}
.ss-gps-12:before {
  content: '\ed0b';
}
.ss-time-64:before {
  content: '\ed0c';
}
.ss-time-48:before {
  content: '\ed0d';
}
.ss-time-32:before {
  content: '\ed0e';
}
.ss-time-24:before {
  content: '\ed0f';
}
.ss-time-16:before {
  content: '\ed10';
}
.ss-time-12:before {
  content: '\ed11';
}
.ss-check-12:before {
  content: '\ed12';
}
.ss-check-16:before {
  content: '\ed13';
}
.ss-check-64:before {
  content: '\ed14';
}
.ss-check-48:before {
  content: '\ed15';
}
.ss-check-32:before {
  content: '\ed16';
}
.ss-check-24:before {
  content: '\ed17';
}
.ss-filter-12:before {
  content: '\ed18';
}
.ss-filter-16:before {
  content: '\ed19';
}
.ss-filter-64:before {
  content: '\ed1a';
}
.ss-filter-48:before {
  content: '\ed1b';
}
.ss-filter-32:before {
  content: '\ed1c';
}
.ss-filter-24:before {
  content: '\ed1d';
}
.ss-calendar-32:before {
  content: '\ed1e';
}
.ss-calendar-48:before {
  content: '\ed1f';
}
.ss-calendar-64:before {
  content: '\ed20';
}
.ss-calendar-12:before {
  content: '\ed21';
}
.ss-calendar-16:before {
  content: '\ed22';
}
.ss-calendar-24:before {
  content: '\ed23';
}
.ss-cash-12:before {
  content: '\ed24';
}
.ss-cash-16:before {
  content: '\ed25';
}
.ss-cash-24:before {
  content: '\ed26';
}
.ss-cash-32:before {
  content: '\ed27';
}
.ss-cash-48:before {
  content: '\ed28';
}
.ss-cash-64:before {
  content: '\ed29';
}
.ss-camera-48:before {
  content: '\ed2a';
}
.ss-camera-12:before {
  content: '\ed2b';
}
.ss-camera-16:before {
  content: '\ed2c';
}
.ss-camera-24:before {
  content: '\ed2d';
}
.ss-camera-32:before {
  content: '\ed2e';
}
.ss-camera-64:before {
  content: '\ed2f';
}
.ss-payment-12:before {
  content: '\ed30';
}
.ss-payment-16:before {
  content: '\ed31';
}
.ss-payment-24:before {
  content: '\ed32';
}
.ss-payment-32:before {
  content: '\ed33';
}
.ss-payment-48:before {
  content: '\ed34';
}
.ss-payment-64:before {
  content: '\ed35';
}
.ss-map-12:before {
  content: '\ed36';
}
.ss-map-16:before {
  content: '\ed37';
}
.ss-map-24:before {
  content: '\ed38';
}
.ss-map-32:before {
  content: '\ed39';
}
.ss-map-48:before {
  content: '\ed3a';
}
.ss-map-64:before {
  content: '\ed3b';
}
.ss-heart-12:before {
  content: '\ed3c';
}
.ss-heart-16:before {
  content: '\ed3d';
}
.ss-heart-24:before {
  content: '\ed3e';
}
.ss-heart-32:before {
  content: '\ed3f';
}
.ss-heart-48:before {
  content: '\ed40';
}
.ss-heart-64:before {
  content: '\ed41';
}
.ss-checkmark-circle-filled-12:before {
  content: '\ed42';
}
.ss-checkmark-circle-filled-16:before {
  content: '\ed43';
}
.ss-checkmark-circle-filled-24:before {
  content: '\ed44';
}
.ss-checkmark-circle-outline-12:before {
  content: '\ed45';
}
.ss-checkmark-circle-outline-16:before {
  content: '\ed46';
}
.ss-checkmark-circle-outline-64:before {
  content: '\ed47';
}
.ss-checkmark-circle-outline-48:before {
  content: '\ed48';
}
.ss-checkmark-circle-outline-32:before {
  content: '\ed49';
}
.ss-checkmark-circle-outline-24:before {
  content: '\ed4a';
}
.ss-chat-12-badge-no:before {
  content: '\ed4f';
}
.ss-chat-16-badge-no:before {
  content: '\ed50';
}
.ss-chat-64-badge-no:before {
  content: '\ed59';
}
.ss-chat-48-badge-no:before {
  content: '\ed5c';
}
.ss-chat-32-badge-no:before {
  content: '\ed5d';
}
.ss-chat-24-badge-no:before {
  content: '\ed5e';
}
.ss-message-64-badge-no:before {
  content: '\ed61';
}
.ss-message-48-badge-no:before {
  content: '\ed67';
}
.ss-message-32-badge-no:before {
  content: '\ed6b';
}
.ss-message-12-badge-no:before {
  content: '\ed71';
}
.ss-message-16-badge-no:before {
  content: '\ed72';
}
.ss-message-24-badge-no:before {
  content: '\ed75';
}
.ss-tasks-12-badge-no:before {
  content: '\ed7a';
}
.ss-tasks-16-badge-no:before {
  content: '\ed7b';
}
.ss-tasks-64-badge-no:before {
  content: '\ed84';
}
.ss-tasks-48-badge-no:before {
  content: '\ed85';
}
.ss-tasks-32-badge-no:before {
  content: '\ed86';
}
.ss-tasks-24-badge-no:before {
  content: '\ed87';
}
.ss-plus-circle-12:before {
  content: '\ed88';
}
.ss-plus-circle-64:before {
  content: '\ed89';
}
.ss-plus-circle-48:before {
  content: '\ed8a';
}
.ss-plus-circle-32:before {
  content: '\ed8b';
}
.ss-plus-circle-24:before {
  content: '\ed8c';
}
.ss-plus-circle-16:before {
  content: '\ed8d';
}
.ss-home-12:before {
  content: '\ed8e';
}
.ss-home-16:before {
  content: '\ed8f';
}
.ss-home-64:before {
  content: '\ed90';
}
.ss-home-48:before {
  content: '\ed91';
}
.ss-home-32:before {
  content: '\ed92';
}
.ss-home-24:before {
  content: '\ed93';
}
.ss-menu-12:before {
  content: '\ed94';
}
.ss-menu-16:before {
  content: '\ed95';
}
.ss-menu-64:before {
  content: '\ed96';
}
.ss-menu-48:before {
  content: '\ed97';
}
.ss-menu-32:before {
  content: '\ed98';
}
.ss-menu-24:before {
  content: '\ed99';
}
.ss-star-12-half:before {
  content: '\ed9a';
}
.ss-star-12-filled:before {
  content: '\ed9b';
}
.ss-star-12-outline:before {
  content: '\ed9c';
}
.ss-star-16-half:before {
  content: '\ed9d';
}
.ss-star-16-filled:before {
  content: '\ed9e';
}
.ss-star-16-outline:before {
  content: '\ed9f';
}
.ss-star-24-half:before {
  content: '\eda0';
}
.ss-star-24-filled:before {
  content: '\eda1';
}
.ss-star-24-outline:before {
  content: '\eda2';
}
.ss-star-32-half:before {
  content: '\eda3';
}
.ss-star-32-filled:before {
  content: '\eda4';
}
.ss-star-32-outline:before {
  content: '\eda5';
}
.ss-star-64-half:before {
  content: '\eda6';
}
.ss-star-64-filled:before {
  content: '\eda8';
}
.ss-location-time-48:before {
  content: '\edaa';
}
.ss-location-time-12:before {
  content: '\edab';
}
.ss-location-time-16:before {
  content: '\edac';
}
.ss-location-time-24:before {
  content: '\edad';
}
.ss-location-time-32:before {
  content: '\edae';
}
.ss-location-time-64:before {
  content: '\edaf';
}
.ss-location-12:before {
  content: '\edb0';
}
.ss-location-16:before {
  content: '\edb1';
}
.ss-location-24:before {
  content: '\edb2';
}
.ss-location-32:before {
  content: '\edb3';
}
.ss-location-48:before {
  content: '\edb4';
}
.ss-location-64:before {
  content: '\edb8';
}
.ss-plus-sign-12:before {
  content: '\edbc';
}
.ss-plus-sign-16:before {
  content: '\edc0';
}
.ss-plus-sign-24:before {
  content: '\edc4';
}
.ss-plus-sign-32:before {
  content: '\edc5';
}
.ss-plus-sign-48:before {
  content: '\edc6';
}
.ss-plus-sign-64:before {
  content: '\edc7';
}
.ss-x-12:before {
  content: '\edc8';
}
.ss-x-16:before {
  content: '\edc9';
}
.ss-x-24:before {
  content: '\edca';
}
.ss-x-32:before {
  content: '\edcb';
}
.ss-x-48:before {
  content: '\edcc';
}
.ss-x-64:before {
  content: '\edcd';
}
.ss-chevron-12-up:before {
  content: '\edce';
}
.ss-chevron-12-down:before {
  content: '\edcf';
}
.ss-chevron-12-left:before {
  content: '\edd0';
}
.ss-chevron-12-right:before {
  content: '\edd1';
}
.ss-chevron-16-up:before {
  content: '\edd2';
}
.ss-chevron-16-down:before {
  content: '\edd3';
}
.ss-chevron-16-left:before {
  content: '\edd4';
}
.ss-chevron-16-right:before {
  content: '\edd5';
}
.ss-chevron-24-up:before {
  content: '\edd6';
}
.ss-chevron-24-down:before {
  content: '\edd7';
}
.ss-chevron-24-left:before {
  content: '\edd8';
}
.ss-chevron-24-right:before {
  content: '\edd9';
}
.ss-chevron-32-up:before {
  content: '\edda';
}
.ss-chevron-32-down:before {
  content: '\eddb';
}
.ss-chevron-32-left:before {
  content: '\eddc';
}
.ss-chevron-32-right:before {
  content: '\eddd';
}
.ss-chevron-48-up:before {
  content: '\edde';
}
.ss-chevron-48-down:before {
  content: '\eddf';
}
.ss-chevron-48-left:before {
  content: '\ede0';
}
.ss-chevron-48-right:before {
  content: '\ede1';
}
.ss-chevron-64-up:before {
  content: '\ede2';
}
.ss-chevron-64-down:before {
  content: '\ede3';
}
.ss-chevron-64-left:before {
  content: '\ede4';
}
.ss-chevron-64-right:before {
  content: '\ede5';
}
.ss-search-12:before {
  content: '\ede6';
}
.ss-search-16:before {
  content: '\ede7';
}
.ss-search-24:before {
  content: '\ede8';
}
.ss-search-32:before {
  content: '\ede9';
}
.ss-search-48:before {
  content: '\edea';
}
.ss-search-64:before {
  content: '\edeb';
}
.ss-lnr-calendar-31:before {
  content: '\ea17';
}
.ss-split:before {
  content: '\e9d6';
}
.ss-eco-globe-64:before {
  content: '\eb8b';
}
.ss-chat-pencil:before {
  content: '\ebc5';
}
.ss-badge:before {
  content: '\ebc6';
}
.ss-id-card:before {
  content: '\ebc7';
}
.ss-phone-call1:before {
  content: '\ebc8';
}
.ss-error:before {
  content: '\ebc9';
}
.ss-calendar-empty1:before {
  content: '\ebca';
}
.ss-checkmark1:before {
  content: '\ebcb';
}
.ss-pin:before {
  content: '\ebd0';
}
.ss-calendar-add:before {
  content: '\ebd1';
}
.ss-get-hired:before {
  content: '\ebd3';
}
.ss-bar-chart:before {
  content: '\ebd4';
}
.ss-clipboard-alert:before {
  content: '\ebd5';
}
.ss-split-arrows:before {
  content: '\ebd6';
}
.ss-chat-alert:before {
  content: '\ebd7';
}
.ss-chat-ellipses:before {
  content: '\ebd8';
}
.ss-signal:before {
  content: '\ebd9';
}
.ss-alert:before {
  content: '\ebda';
}
.ss-drill:before {
  content: '\ebdc';
}
.ss-pause-circle:before {
  content: '\ebdd';
}
.ss-lightbulb:before {
  content: '\ebde';
}
.ss-thumbsup:before {
  content: '\ebdf';
}
.ss-thumbsdown:before {
  content: '\ebe0';
}
.ss-alert-checkmark:before {
  content: '\ebe1';
}
.ss-shield-checkmark:before {
  content: '\ebe2';
}
.ss-edit:before {
  content: '\ebe3';
}
.ss-eye-crossed:before {
  content: '\ebe5';
}
.ss-eye-open:before {
  content: '\ebe6';
}
.ss-minus-circle1:before {
  content: '\ebe7';
}
.ss-arrow-12-down:before {
  content: '\e9df';
}
.ss-arrow-12-left:before {
  content: '\e9e0';
}
.ss-arrow-12-right:before {
  content: '\e9e1';
}
.ss-arrow-12-up:before {
  content: '\e9e2';
}
.ss-arrow-16-down:before {
  content: '\e9e3';
}
.ss-arrow-16-left:before {
  content: '\e9e4';
}
.ss-arrow-16-right:before {
  content: '\e9e5';
}
.ss-arrow-16-up:before {
  content: '\e9e6';
}
.ss-arrow-24-down:before {
  content: '\e9e7';
}
.ss-arrow-24-left:before {
  content: '\e9e8';
}
.ss-arrow-24-right:before {
  content: '\e9e9';
}
.ss-arrow-24-up:before {
  content: '\e9ea';
}
.ss-arrow-32-down:before {
  content: '\e9eb';
}
.ss-arrow-32-left:before {
  content: '\e9ec';
}
.ss-arrow-32-right:before {
  content: '\e9ed';
}
.ss-arrow-32-up:before {
  content: '\e9f2';
}
.ss-arrow-48-down:before {
  content: '\e9f3';
}
.ss-arrow-48-left:before {
  content: '\e9f4';
}
.ss-arrow-48-right:before {
  content: '\e9f5';
}
.ss-arrow-48-up:before {
  content: '\e9f6';
}
.ss-arrow-64-down:before {
  content: '\e9f7';
}
.ss-arrow-64-left:before {
  content: '\e9f8';
}
.ss-arrow-64-right:before {
  content: '\e9f9';
}
.ss-arrow-64-up:before {
  content: '\e9fa';
}
.ss-number-filled-12:before {
  content: '\ea9e';
}
.ss-number-filled-16:before {
  content: '\ea9f';
}
.ss-number-filled-24:before {
  content: '\eaa0';
}
.ss-number-outline-12:before {
  content: '\eaa1';
}
.ss-number-outline-16:before {
  content: '\eaa2';
}
.ss-number-outline-24:before {
  content: '\eaa3';
}
.ss-icon-thick-search:before {
  content: '\e9d7';
}
.ss-pet1:before {
  content: '\ea16';
}
.ss-favorite-heart:before {
  content: '\e9d4';
}
.ss-lnr-bag-euro:before {
  content: '\e9d1';
}
.ss-lnr-cash-euro:before {
  content: '\e9d2';
}
.ss-cash-lined-euro:before {
  content: '\e9d3';
}
.ss-document:before {
  content: '\e9ce';
}
.ss-calendar-full:before {
  content: '\e9cf';
}
.ss-car-2:before {
  content: '\e9d0';
}
.ss-lnr-user-plus:before {
  content: '\e9cd';
}
.ss-log-out:before {
  content: '\e9cc';
}
.ss-lnr-user-minus:before {
  content: '\e9cb';
}
.ss-list-5:before {
  content: '\e9ca';
}
.ss-trash-2:before {
  content: '\e9ba';
}
.ss-picture-2:before {
  content: '\e9bb';
}
.ss-quote-open:before {
  content: '\e9bc';
}
.ss-quote-close:before {
  content: '\e9bd';
}
.ss-redo:before {
  content: '\e9be';
}
.ss-list-2:before {
  content: '\e9bf';
}
.ss-list-3:before {
  content: '\e9c0';
}
.ss-bold-copy:before {
  content: '\e9c1';
}
.ss-italic:before {
  content: '\e9c2';
}
.ss-underline:before {
  content: '\e9c3';
}
.ss-text-align-left:before {
  content: '\e9c4';
}
.ss-text-align-center:before {
  content: '\e9c5';
}
.ss-text-align-right:before {
  content: '\e9c6';
}
.ss-text-align-justify:before {
  content: '\e9c7';
}
.ss-indent-increase:before {
  content: '\e9c8';
}
.ss-indent-decrease:before {
  content: '\e9c9';
}
.ss-round-undo:before {
  content: '\e9b9';
}
.ss-new-undo:before {
  content: '\e9b6';
}
.ss-new-expand:before {
  content: '\e9b7';
}
.ss-new-contract:before {
  content: '\e9b8';
}
.ss-users2:before {
  content: '\e9b5';
}
.ss-goto-team:before {
  content: '\e9b4';
}
.ss-lnr-bed:before {
  content: '\e95f';
}
.ss-lnr-couch:before {
  content: '\e985';
}
.ss-lnr-eraser:before {
  content: '\e986';
}
.ss-lnr-magic-wand:before {
  content: '\e987';
}
.ss-lnr-fire:before {
  content: '\e988';
}
.ss-lnr-sun:before {
  content: '\e989';
}
.ss-lnr-moon:before {
  content: '\e98a';
}
.ss-lnr-hammer:before {
  content: '\e98b';
}
.ss-lnr-recycle:before {
  content: '\e98c';
}
.ss-lnr-mailbox-full:before {
  content: '\e98d';
}
.ss-lnr-mailbox-empty:before {
  content: '\e98e';
}
.ss-lnr-envelope-open:before {
  content: '\e98f';
}
.ss-lnr-paperclip:before {
  content: '\e990';
}
.ss-lnr-inbox2:before {
  content: '\e991';
}
.ss-lnr-outbox:before {
  content: '\e992';
}
.ss-lnr-archive:before {
  content: '\e993';
}
.ss-lnr-drawers:before {
  content: '\e994';
}
.ss-lnr-drawers2:before {
  content: '\e995';
}
.ss-lnr-clipboard-empty:before {
  content: '\e996';
}
.ss-clipboard-pencil:before {
  content: '\e997';
}
.ss-lnr-clipboard-pencil:before {
  content: '\ea1c';
}
.ss-lnr-clipboard-text:before {
  content: '\e998';
}
.ss-lnr-clipboard-user:before {
  content: '\e999';
}
.ss-lnr-license2:before {
  content: '\e99a';
}
.ss-lnr-trophy2:before {
  content: '\e99b';
}
.ss-lnr-shuffle:before {
  content: '\e99c';
}
.ss-lnr-repeat:before {
  content: '\e99d';
}
.ss-lnr-picture:before {
  content: '\e99e';
}
.ss-lnr-book:before {
  content: '\e99f';
}
.ss-lnr-library:before {
  content: '\e9a0';
}
.ss-lnr-tags:before {
  content: '\e9a1';
}
.ss-lnr-compass2:before {
  content: '\e9a2';
}
.ss-lnr-first-aid:before {
  content: '\e9a3';
}
.ss-lnr-lifebuoy:before {
  content: '\e9a4';
}
.ss-lnr-briefcase:before {
  content: '\e9a5';
}
.ss-lnr-plane:before {
  content: '\e9a6';
}
.ss-lnr-smile:before {
  content: '\e9a7';
}
.ss-lnr-bullhorn:before {
  content: '\e9a8';
}
.ss-lnr-refresh:before {
  content: '\e9a9';
}
.ss-lnr-share2:before {
  content: '\e9aa';
}
.ss-lnr-list:before {
  content: '\e9ab';
}
.ss-lnr-notification:before {
  content: '\e9ac';
}
.ss-lnr-funnel:before {
  content: '\e9ad';
}
.ss-lnr-hand:before {
  content: '\e9ae';
}
.ss-lnr-pointer-up:before {
  content: '\e9af';
}
.ss-lnr-fingers-scroll-horizontal:before {
  content: '\e9b0';
}
.ss-lnr-finger-draw:before {
  content: '\e9b1';
}
.ss-lnr-checkmark-badge:before {
  content: '\e9b2';
}
.ss-lnr-receipt2:before {
  content: '\e9b3';
}
.ss-lnr-plumbing:before {
  content: '\e984';
}
.ss-lnr-shield-check:before {
  content: '\e951';
}
.ss-lnr-shield-alert:before {
  content: '\e952';
}
.ss-lnr-shield-cross:before {
  content: '\e953';
}
.ss-lnr-lock:before {
  content: '\e954';
}
.ss-lnr-unlock:before {
  content: '\e955';
}
.ss-lnr-eye:before {
  content: '\e956';
}
.ss-lnr-eye-crossed:before {
  content: '\e957';
}
.ss-lnr-floppy-disk:before {
  content: '\e958';
}
.ss-lnr-equalizer:before {
  content: '\e959';
}
.ss-lnr-camera:before {
  content: '\e95a';
}
.ss-lnr-bookmark2:before {
  content: '\e95b';
}
.ss-lnr-barcode3:before {
  content: '\e95c';
}
.ss-lnr-cart-plus2:before {
  content: '\e95d';
}
.ss-lnr-tag:before {
  content: '\e95e';
}
.ss-lnr-bag-pound:before {
  content: '\e960';
}
.ss-lnr-calculator:before {
  content: '\e961';
}
.ss-lnr-calculator2:before {
  content: '\e962';
}
.ss-lnr-map2:before {
  content: '\e963';
}
.ss-lnr-bubbles:before {
  content: '\e964';
}
.ss-lnr-diamond2:before {
  content: '\e965';
}
.ss-lnr-leaf:before {
  content: '\e966';
}
.ss-lnr-speed-fast:before {
  content: '\e967';
}
.ss-lnr-car:before {
  content: '\e968';
}
.ss-lnr-bus:before {
  content: '\e969';
}
.ss-lnr-truck:before {
  content: '\e96a';
}
.ss-lnr-wheelchair:before {
  content: '\e96b';
}
.ss-lnr-happy:before {
  content: '\e96c';
}
.ss-lnr-sad:before {
  content: '\e96d';
}
.ss-lnr-neutral:before {
  content: '\e96e';
}
.ss-lnr-happy-grin:before {
  content: '\e96f';
}
.ss-lnr-annoyed:before {
  content: '\e970';
}
.ss-alarm:before {
  content: '\e971';
}
.ss-lnr-antenna:before {
  content: '\e972';
}
.ss-lnr-mic:before {
  content: '\e973';
}
.ss-lnr-mic-mute:before {
  content: '\e974';
}
.ss-lnr-download2:before {
  content: '\e975';
}
.ss-lnr-upload2:before {
  content: '\e976';
}
.ss-lnr-link:before {
  content: '\e977';
}
.ss-lnr-arrow-up:before {
  content: '\e978';
}
.ss-lnr-arrow-down:before {
  content: '\e979';
}
.ss-lnr-arrow-left:before {
  content: '\e97a';
}
.ss-lnr-arrow-up-right:before {
  content: '\e97b';
}
.ss-lnr-expand2:before {
  content: '\e97c';
}
.ss-lnr-contract2:before {
  content: '\e97d';
}
.ss-lnr-warning:before {
  content: '\e97e';
}
.ss-lnr-question-circle:before {
  content: '\e97f';
}
.ss-lnr-rulers:before {
  content: '\e980';
}
.ss-lnr-square:before {
  content: '\e981';
}
.ss-lnr-ellipsis:before {
  content: '\e982';
}
.ss-lnr-sort:before {
  content: '\e983';
}
.ss-trust-icon:before {
  content: '\e950';
}
.ss-lnr-cord:before {
  content: '\e94f';
}
.ss-lnr-map-circle:before {
  content: '\e94d';
}
.ss-lnr-plus:before {
  content: '\e94e';
}
.ss-lnr-map:before {
  content: '\e94c';
}
.ss-lnr-envelope:before {
  content: '\e94b';
}
.ss-lnr-review:before {
  content: '\e936';
}
.ss-lnr-trash:before {
  content: '\e948';
}
.ss-lnr-flag:before {
  content: '\e949';
}
.ss-lnr-share:before {
  content: '\e94a';
}
.ss-alarm-snooze:before {
  content: '\e946';
}
.ss-alarm-ringing:before {
  content: '\e947';
}
.ss-medal-empty:before {
  content: '\e945';
}
.ss-lnr-timer:before {
  content: '\e942';
}
.ss-lnr-search:before {
  content: '\e943';
}
.ss-lnr-gift:before {
  content: '\e944';
}
.ss-facebook-center:before {
  content: '\e941';
}
.ss-lnr-credit-card:before {
  content: '\e934';
}
.ss-lnr-receipt:before {
  content: '\e940';
}
.ss-lnr-cog:before {
  content: '\e92f';
}
.ss-lnr-heart:before {
  content: '\e930';
}
.ss-lnr-star:before {
  content: '\e931';
}
.ss-lnr-calendar-cross:before {
  content: '\e932';
}
.ss-lnr-calendar-3:before {
  content: '\e933';
}
.ss-lnr-calendar-user:before {
  content: '\e935';
}
.ss-lnr-clock:before {
  content: '\e937';
}
.ss-lnr-alarm:before {
  content: '\e938';
}
.ss-lnr-menu:before {
  content: '\e939';
}
.ss-lnr-chevron-up:before {
  content: '\e93a';
}
.ss-lnr-chevron-down:before {
  content: '\e93b';
}
.ss-lnr-chevron-left:before {
  content: '\e93c';
}
.ss-lnr-chevron-right:before {
  content: '\e93d';
}
.ss-lnr-chevrons-expand-vertical:before {
  content: '\e93e';
}
.ss-lnr-circle:before {
  content: '\e93f';
}
.ss-lnr-cross2:before {
  content: '\e92e';
}
.ss-lnr-lightbulb:before {
  content: '\e924';
}
.ss-lnr-check-circle:before {
  content: '\e925';
}
.ss-lnr-plus-circle:before {
  content: '\e926';
}
.ss-lnr-check-square:before {
  content: '\e927';
}
.ss-lnr-drop:before {
  content: '\e928';
}
.ss-lnr-sync:before {
  content: '\e929';
}
.ss-lnr-check:before {
  content: '\e92a';
}
.ss-lnr-bag-dollar:before {
  content: '\e92b';
}
.ss-lnr-cash-dollar:before {
  content: '\e92c';
}
.ss-lnr-circle-minus:before {
  content: '\e92d';
}
.ss-lnr-power:before {
  content: '\e923';
}
.ss-lnr-vacuum:before {
  content: '\e920';
}
.ss-lnr-spray:before {
  content: '\e921';
}
.ss-lnr-broom:before {
  content: '\e922';
}
.ss-calendar-empty:before {
  content: '\e91d';
}
.ss-bubble-lined2:before {
  content: '\e91e';
}
.ss-truck2:before {
  content: '\e91f';
}
.ss-finger-tap:before {
  content: '\e918';
}
.ss-checkmark-circle2:before {
  content: '\e919';
}
.ss-calendar-check:before {
  content: '\e91a';
}
.ss-portrait:before {
  content: '\e91b';
}
.ss-shield-blank:before {
  content: '\e91c';
}
.ss-clock-lined-alt:before {
  content: '\e915';
}
.ss-clipboard-check:before {
  content: '\e916';
}
.ss-arrow-right:before {
  content: '\e917';
}
.ss-city-lined:before {
  content: '\e914';
}
.ss-cash-lined-gb:before {
  content: '\e913';
}
.ss-calendar-lined:before {
  content: '\e910';
}
.ss-cash-lined:before {
  content: '\e911';
}
.ss-tools:before {
  content: '\e912';
}
.ss-rkt-handyman:before {
  content: '\e901';
}
.ss-rkt-location:before {
  content: '\e902';
}
.ss-rkt-phone:before {
  content: '\e903';
}
.ss-rkt-home:before {
  content: '\e904';
}
.ss-rkt-thumbdown:before {
  content: '\e905';
}
.ss-rkt-thumbup:before {
  content: '\e906';
}
.ss-rkt-moving:before {
  content: '\e907';
}
.ss-rkt-tasks:before {
  content: '\e908';
}
.ss-rkt-camera:before {
  content: '\e909';
}
.ss-rkt-profile:before {
  content: '\e90a';
}
.ss-rkt-general:before {
  content: '\e90b';
}
.ss-rkt-notes:before {
  content: '\e90c';
}
.ss-rkt-pin:before {
  content: '\e90d';
}
.ss-rkt-edit:before {
  content: '\e90e';
}
.ss-rkt-pin2:before {
  content: '\e90f';
}
.ss-recurring:before {
  content: '\e900';
}
.ss-dislike-lined:before {
  content: '\e60a';
}
.ss-like-lined:before {
  content: '\e60b';
}
.ss-cleaning:before {
  content: '\e601';
}
.ss-handyman:before {
  content: '\e602';
}
.ss-moving:before {
  content: '\e603';
}
.ss-assistance:before {
  content: '\e604';
}
.ss-holiday:before {
  content: '\e605';
}
.ss-other:before {
  content: '\e606';
}
.ss-pantone:before {
  content: '\e024';
}
.ss-vector:before {
  content: '\e43e';
}
.ss-spray:before {
  content: '\e023';
}
.ss-camera-lined:before {
  content: '\e035';
}
.ss-clapboard:before {
  content: '\e046';
}
.ss-books:before {
  content: '\e073';
}
.ss-profile:before {
  content: '\e078';
}
.ss-mailbox:before {
  content: '\ea35';
}
.ss-city:before {
  content: '\e00e';
}
.ss-magazine:before {
  content: '\e00f';
}
.ss-bucket:before {
  content: '\e022';
}
.ss-droplet:before {
  content: '\e028';
}
.ss-droplet-lined:before {
  content: '\e029';
}
.ss-droplet-half:before {
  content: '\e02d';
}
.ss-color-clear:before {
  content: '\e02e';
}
.ss-image:before {
  content: '\e02f';
}
.ss-images:before {
  content: '\e030';
}
.ss-guitar:before {
  content: '\e03f';
}
.ss-trumpet:before {
  content: '\e040';
}
.ss-media:before {
  content: '\e045';
}
.ss-dice:before {
  content: '\e050';
}
.ss-gamepad:before {
  content: '\e600';
}
.ss-bullhorn:before {
  content: '\e05f';
}
.ss-megaphone:before {
  content: '\e060';
}
.ss-new:before {
  content: '\e063';
}
.ss-microphone:before {
  content: '\e06f';
}
.ss-book-marked:before {
  content: '\e072';
}
.ss-library:before {
  content: '\e075';
}
.ss-price-tag:before {
  content: '\e9ee';
}
.ss-price-tags:before {
  content: '\e9ef';
}
.ss-price-tag-lined:before {
  content: '\e9f0';
}
.ss-price-tags-lined:before {
  content: '\e9f1';
}
.ss-ticket:before {
  content: '\e0c0';
}
.ss-theater:before {
  content: '\e9fb';
}
.ss-store:before {
  content: '\e0c4';
}
.ss-cart:before {
  content: '\e0c5';
}
.ss-basket:before {
  content: '\e0ce';
}
.ss-bag:before {
  content: '\e0d0';
}
.ss-percent:before {
  content: '\e0d6';
}
.ss-coin-euro:before {
  content: '\e0d7';
}
.ss-coin-pound:before {
  content: '\e0d9';
}
.ss-coin-yen:before {
  content: '\e0da';
}
.ss-piggy-bank:before {
  content: '\e0db';
}
.ss-wallet:before {
  content: '\e0e4';
}
.ss-cash:before {
  content: '\e0e5';
}
.ss-cash-stack:before {
  content: '\e0e7';
}
.ss-credit-card:before {
  content: '\e0ea';
}
.ss-calculator:before {
  content: '\e0ec';
}
.ss-lifebuoy:before {
  content: '\e0ee';
}
.ss-phone-off:before {
  content: '\e0ef';
}
.ss-phone-lined:before {
  content: '\e0f2';
}
.ss-phone-off-lined:before {
  content: '\e0f4';
}
.ss-phone-call-lined:before {
  content: '\e0f5';
}
.ss-at-sign:before {
  content: '\e0f8';
}
.ss-envelope-alt:before {
  content: '\e0fa';
}
.ss-envelope-alt-lined:before {
  content: '\e0fb';
}
.ss-location-pinned:before {
  content: '\e0ff';
}
.ss-compass-lined:before {
  content: '\e102';
}
.ss-compass-half:before {
  content: '\e103';
}
.ss-compass:before {
  content: '\e104';
}
.ss-compass-circle:before {
  content: '\e105';
}
.ss-reset:before {
  content: '\e607';
}
.ss-reminder:before {
  content: '\e10a';
}
.ss-reminder-add:before {
  content: '\ea54';
}
.ss-reminder-check:before {
  content: '\ea55';
}
.ss-reminder-cancel:before {
  content: '\ea56';
}
.ss-alarm-sound:before {
  content: '\e111';
}
.ss-calendar-alt:before {
  content: '\e115';
}
.ss-typewriter:before {
  content: '\e121';
}
.ss-display:before {
  content: '\e123';
}
.ss-download:before {
  content: '\e132';
}
.ss-upload:before {
  content: '\e133';
}
.ss-chat-lined:before {
  content: '\e153';
}
.ss-chatting-lined-alt:before {
  content: '\e154';
}
.ss-chatting-lined:before {
  content: '\e155';
}
.ss-chat-notification:before {
  content: '\e156';
}
.ss-bubble-smiley:before {
  content: '\e18e';
}
.ss-user-lock:before {
  content: '\e18f';
}
.ss-user-check:before {
  content: '\e190';
}
.ss-sock:before {
  content: '\e1a3';
}
.ss-laundry:before {
  content: '\e1a4';
}
.ss-hour-glass:before {
  content: '\e1a5';
}
.ss-search-fat:before {
  content: '\eb2d';
}
.ss-zoom-in-fat:before {
  content: '\eb2e';
}
.ss-zoom-out-fat:before {
  content: '\eb2f';
}
.ss-search:before {
  content: '\e1b8';
}
.ss-zoom-in:before {
  content: '\e1b9';
}
.ss-zoom-out:before {
  content: '\e1be';
}
.ss-safe:before {
  content: '\e1d9';
}
.ss-wrench:before {
  content: '\e1dd';
}
.ss-aid-kit:before {
  content: '\e1f1';
}
.ss-stethoscope:before {
  content: '\e1f3';
}
.ss-bandage:before {
  content: '\e1fd';
}
.ss-pill:before {
  content: '\e200';
}
.ss-stars:before {
  content: '\e217';
}
.ss-medal-star:before {
  content: '\e21d';
}
.ss-ruby:before {
  content: '\eba8';
}
.ss-mustache:before {
  content: '\ebad';
}
.ss-glass-full:before {
  content: '\e233';
}
.ss-glass-empty:before {
  content: '\e234';
}
.ss-mug:before {
  content: '\e237';
}
.ss-cook:before {
  content: '\e239';
}
.ss-steak:before {
  content: '\e23f';
}
.ss-fish:before {
  content: '\e243';
}
.ss-chicken:before {
  content: '\e246';
}
.ss-pizza:before {
  content: '\e249';
}
.ss-cup-half:before {
  content: '\e24f';
}
.ss-cup:before {
  content: '\e250';
}
.ss-coffee-bean:before {
  content: '\e252';
}
.ss-coffee:before {
  content: '\e253';
}
.ss-teapot:before {
  content: '\e254';
}
.ss-grapes:before {
  content: '\e255';
}
.ss-tree:before {
  content: '\e256';
}
.ss-tree-lined:before {
  content: '\e25a';
}
.ss-cactus:before {
  content: '\e2c7';
}
.ss-mountains:before {
  content: '\e2c8';
}
.ss-lamp:before {
  content: '\e25d';
}
.ss-lamp-lined:before {
  content: '\e25e';
}
.ss-bulb:before {
  content: '\e25f';
}
.ss-bulb-lined:before {
  content: '\e262';
}
.ss-broom:before {
  content: '\e264';
}
.ss-toiletpaper:before {
  content: '\e265';
}
.ss-bin:before {
  content: '\e266';
}
.ss-airplane:before {
  content: '\e267';
}
.ss-car:before {
  content: '\e268';
}
.ss-taxi:before {
  content: '\e269';
}
.ss-gas:before {
  content: '\e26e';
}
.ss-bus:before {
  content: '\e26f';
}
.ss-road:before {
  content: '\e272';
}
.ss-choo-choo:before {
  content: '\e273';
}
.ss-train:before {
  content: '\e274';
}
.ss-ship:before {
  content: '\e275';
}
.ss-boat:before {
  content: '\e276';
}
.ss-puzzle:before {
  content: '\e27b';
}
.ss-puzzle-lined:before {
  content: '\e27e';
}
.ss-glasses-3d:before {
  content: '\e280';
}
.ss-brain:before {
  content: '\e281';
}
.ss-accessibility:before {
  content: '\e28d';
}
.ss-strategy:before {
  content: '\e28f';
}
.ss-target:before {
  content: '\e291';
}
.ss-shield:before {
  content: '\e294';
}
.ss-shield-check:before {
  content: '\e295';
}
.ss-shield-notice:before {
  content: '\e296';
}
.ss-archery:before {
  content: '\e2a2';
}
.ss-bow:before {
  content: '\e2a3';
}
.ss-sword:before {
  content: '\e2a5';
}
.ss-pingpong:before {
  content: '\e2ac';
}
.ss-golf:before {
  content: '\e2b0';
}
.ss-power:before {
  content: '\e2b5';
}
.ss-clipboard-lined:before {
  content: '\e2b6';
}
.ss-clipboard-lined-check:before {
  content: '\e2b7';
}
.ss-clipboard-lined-info:before {
  content: '\e2b8';
}
.ss-clipboard-empty:before {
  content: '\e2b9';
}
.ss-clipboard-empty-check:before {
  content: '\e2ba';
}
.ss-clipboard-empty-info:before {
  content: '\e2bb';
}
.ss-grid:before {
  content: '\e2bc';
}
.ss-grid-circle:before {
  content: '\e2bd';
}
.ss-grid-lined:before {
  content: '\e2be';
}
.ss-lan:before {
  content: '\e2c9';
}
.ss-lan-alt:before {
  content: '\e2ca';
}
.ss-connect:before {
  content: '\e2cb';
}
.ss-menu-overflow:before {
  content: '\e2bf';
}
.ss-menu-drop:before {
  content: '\ec69';
}
.ss-menu:before {
  content: '\e2c0';
}
.ss-menu-expand:before {
  content: '\e2c1';
}
.ss-menu-down:before {
  content: '\e2c2';
}
.ss-menu-up:before {
  content: '\e2c3';
}
.ss-cloud:before {
  content: '\e2ce';
}
.ss-cloud-download:before {
  content: '\e2d2';
}
.ss-cloud-upload:before {
  content: '\e2d3';
}
.ss-cloud-check:before {
  content: '\e2d4';
}
.ss-sphere:before {
  content: '\e2d5';
}
.ss-sphere-half:before {
  content: '\e2d6';
}
.ss-earth-lined:before {
  content: '\e2dd';
}
.ss-earth:before {
  content: '\e2de';
}
.ss-unlink:before {
  content: '\e2e0';
}
.ss-flag:before {
  content: '\e2f0';
}
.ss-flag-lined:before {
  content: '\e2f2';
}
.ss-attachment:before {
  content: '\e2f3';
}
.ss-eye:before {
  content: '\e2fb';
}
.ss-eye-blocked:before {
  content: '\e2fe';
}
.ss-bookmarks:before {
  content: '\e302';
}
.ss-book-marked-lined:before {
  content: '\e303';
}
.ss-sun:before {
  content: '\e321';
}
.ss-furniture:before {
  content: '\e324';
}
.ss-chair:before {
  content: '\e325';
}
.ss-star-lined:before {
  content: '\e32a';
}
.ss-star-half:before {
  content: '\e32b';
}
.ss-star:before {
  content: '\e32c';
}
.ss-star-circle:before {
  content: '\e32d';
}
.ss-height:before {
  content: '\e332';
}
.ss-man-woman:before {
  content: '\e333';
}
.ss-smile-lined:before {
  content: '\e334';
}
.ss-smile:before {
  content: '\e335';
}
.ss-sad-lined:before {
  content: '\e336';
}
.ss-sad:before {
  content: '\e337';
}
.ss-confused-lined:before {
  content: '\e33e';
}
.ss-confused:before {
  content: '\e33f';
}
.ss-pointer:before {
  content: '\e34c';
}
.ss-reminder-hand:before {
  content: '\e34d';
}
.ss-drag-left-right:before {
  content: '\e34f';
}
.ss-drag-left:before {
  content: '\e355';
}
.ss-drag-right:before {
  content: '\e356';
}
.ss-touch:before {
  content: '\e359';
}
.ss-multitouch:before {
  content: '\e35a';
}
.ss-touch-zoom:before {
  content: '\e35d';
}
.ss-touch-pinch:before {
  content: '\e36d';
}
.ss-hand:before {
  content: '\e36e';
}
.ss-warning-lined:before {
  content: '\e37a';
}
.ss-warning:before {
  content: '\e37d';
}
.ss-question-lined:before {
  content: '\e608';
}
.ss-question:before {
  content: '\e609';
}
.ss-plus-light:before {
  content: '\ed5a';
}
.ss-minus-light:before {
  content: '\ed5b';
}
.ss-cancel-circle-lined:before {
  content: '\ed62';
}
.ss-cancel-circle:before {
  content: '\e37e';
}
.ss-cancel-square-lined:before {
  content: '\ed65';
}
.ss-cancel-square:before {
  content: '\ed66';
}
.ss-cross:before {
  content: '\ed6a';
}
.ss-loop:before {
  content: '\eda7';
}
.ss-merge:before {
  content: '\e3b8';
}
.ss-caret-up-semi:before {
  content: '\edb5';
}
.ss-caret-up:before {
  content: '\edb6';
}
.ss-caret-up-light:before {
  content: '\edb7';
}
.ss-caret-right-semi:before {
  content: '\edb9';
}
.ss-caret-right:before {
  content: '\edba';
}
.ss-caret-right-light:before {
  content: '\edbb';
}
.ss-caret-down-semi:before {
  content: '\edbd';
}
.ss-caret-down:before {
  content: '\edbe';
}
.ss-caret-down-light:before {
  content: '\edbf';
}
.ss-caret-left-semi:before {
  content: '\edc1';
}
.ss-caret-left:before {
  content: '\edc2';
}
.ss-caret-left-light:before {
  content: '\edc3';
}
.ss-resize-nesw:before {
  content: '\edfa';
}
.ss-resize-nwse:before {
  content: '\edfb';
}
.ss-resize-ew:before {
  content: '\edfc';
}
.ss-resize-ns:before {
  content: '\edfd';
}
.ss-item-expand:before {
  content: '\e418';
}
.ss-item-down:before {
  content: '\e419';
}
.ss-item-close:before {
  content: '\e41a';
}
.ss-move-up:before {
  content: '\ee45';
}
.ss-move-down:before {
  content: '\ee46';
}
.ss-sort-alpha-asc:before {
  content: '\ee47';
}
.ss-sort-alpha-desc:before {
  content: '\ee48';
}
.ss-sort-numeric-asc:before {
  content: '\ee49';
}
.ss-sort-numberic-desc:before {
  content: '\ee4a';
}
.ss-checkbox-unchecked:before {
  content: '\e432';
}
.ss-checkbox-partial:before {
  content: '\e433';
}
.ss-square:before {
  content: '\e434';
}
.ss-diamond:before {
  content: '\ee67';
}
.ss-diamond3:before {
  content: '\ee68';
}
.ss-checkbox-unchecked2:before {
  content: '\ee6a';
}
.ss-radio-checked:before {
  content: '\e43a';
}
.ss-toggle-on:before {
  content: '\ee6f';
}
.ss-toggle-off:before {
  content: '\ee70';
}
.ss-radio-unchecked:before {
  content: '\e43d';
}
.ss-pencil-ruler:before {
  content: '\e442';
}
.ss-scissors:before {
  content: '\e443';
}
.ss-haircut:before {
  content: '\e444';
}
.ss-ampersand:before {
  content: '\e448';
}
.ss-page-break:before {
  content: '\e45c';
}
.ss-share:before {
  content: '\e47b';
}
.ss-share-ios:before {
  content: '\e47c';
}
.ss-new-tab:before {
  content: '\e47d';
}
.ss-embed:before {
  content: '\e482';
}
.ss-facebook-f:before {
  content: '\e49a';
}
.ss-facebook:before {
  content: '\e49b';
}
.ss-ello:before {
  content: '\eee9';
}
.ss-youtube:before {
  content: '\e4a5';
}
.ss-dropbox:before {
  content: '\ef09';
}
.ss-github:before {
  content: '\ef0b';
}
.ss-linkedin:before {
  content: '\e4d0';
}
.ss-foursquare:before {
  content: '\e4db';
}
.ss-paypal:before {
  content: '\e4dd';
}
.ss-home:before {
  content: '\e001';
}
.ss-home-lined:before {
  content: '\e005';
}
.ss-office:before {
  content: '\e00b';
}
.ss-newspaper:before {
  content: '\e00c';
}
.ss-pencil:before {
  content: '\e00d';
}
.ss-palette:before {
  content: '\e021';
}
.ss-paint-format:before {
  content: '\e02a';
}
.ss-camera:before {
  content: '\e036';
}
.ss-play-video:before {
  content: '\e042';
}
.ss-film:before {
  content: '\e049';
}
.ss-video:before {
  content: '\e04c';
}
.ss-video-lined:before {
  content: '\e04d';
}
.ss-connection:before {
  content: '\e062';
}
.ss-radio:before {
  content: '\e068';
}
.ss-mic:before {
  content: '\e06d';
}
.ss-mic-off:before {
  content: '\e06e';
}
.ss-book:before {
  content: '\e071';
}
.ss-bank:before {
  content: '\e074';
}
.ss-file-empty:before {
  content: '\e085';
}
.ss-file:before {
  content: '\e086';
}
.ss-file-plus:before {
  content: '\e087';
}
.ss-file-minus:before {
  content: '\e088';
}
.ss-file-download:before {
  content: '\e089';
}
.ss-file-upload:before {
  content: '\e08a';
}
.ss-file-check:before {
  content: '\e08b';
}
.ss-file-remove:before {
  content: '\e08c';
}
.ss-file-lined:before {
  content: '\e08d';
}
.ss-copy:before {
  content: '\e08e';
}
.ss-clipboard-lined-copy:before {
  content: '\e08f';
}
.ss-clipboard-copy:before {
  content: '\e090';
}
.ss-clipboard-lined-paste:before {
  content: '\e092';
}
.ss-clipboard-paste:before {
  content: '\e093';
}
.ss-stack:before {
  content: '\e095';
}
.ss-stack-lined:before {
  content: '\e096';
}
.ss-stack-filled:before {
  content: '\e097';
}
.ss-folder:before {
  content: '\e0a1';
}
.ss-folder-open:before {
  content: '\e0a2';
}
.ss-folder-lined:before {
  content: '\e0a4';
}
.ss-certificate:before {
  content: '\e0b1';
}
.ss-cc:before {
  content: '\e0b2';
}
.ss-pricetag:before {
  content: '\e0bc';
}
.ss-coin:before {
  content: '\e0d3';
}
.ss-coins:before {
  content: '\e0d4';
}
.ss-credit:before {
  content: '\e0d5';
}
.ss-calculate:before {
  content: '\e0d8';
}
.ss-phone:before {
  content: '\e0dc';
}
.ss-phone-call:before {
  content: '\e0dd';
}
.ss-contact-add:before {
  content: '\e0e0';
}
.ss-contact-remove:before {
  content: '\e0e1';
}
.ss-call-incoming:before {
  content: '\e0e2';
}
.ss-call-outgoing:before {
  content: '\e0e3';
}
.ss-address-book:before {
  content: '\e0e8';
}
.ss-envelope:before {
  content: '\e0eb';
}
.ss-pushpin:before {
  content: '\e0f0';
}
.ss-location:before {
  content: '\e0f1';
}
.ss-map:before {
  content: '\e0fc';
}
.ss-map-lined:before {
  content: '\e0fd';
}
.ss-direction:before {
  content: '\e0fe';
}
.ss-history:before {
  content: '\e100';
}
.ss-clock-lined:before {
  content: '\e107';
}
.ss-clock:before {
  content: '\e108';
}
.ss-alarm2:before {
  content: '\e10c';
}
.ss-alarm-plus:before {
  content: '\e10d';
}
.ss-alarm-minus:before {
  content: '\e10e';
}
.ss-alarm-check:before {
  content: '\e10f';
}
.ss-alarm-cancel:before {
  content: '\e110';
}
.ss-calendar:before {
  content: '\e113';
}
.ss-print:before {
  content: '\e118';
}
.ss-print-alt:before {
  content: '\e119';
}
.ss-keyboard:before {
  content: '\e11f';
}
.ss-screen:before {
  content: '\e120';
}
.ss-laptop:before {
  content: '\e124';
}
.ss-mobile:before {
  content: '\e125';
}
.ss-phablet:before {
  content: '\e126';
}
.ss-tablet:before {
  content: '\e127';
}
.ss-tv:before {
  content: '\e129';
}
.ss-drawer:before {
  content: '\e12c';
}
.ss-drawer-full:before {
  content: '\e12d';
}
.ss-drawer-full-alt:before {
  content: '\e12e';
}
.ss-disk:before {
  content: '\e134';
}
.ss-chat:before {
  content: '\e150';
}
.ss-chatting-alt:before {
  content: '\e151';
}
.ss-chatting:before {
  content: '\e152';
}
.ss-bubble2:before {
  content: '\e164';
}
.ss-bubble-dots:before {
  content: '\e165';
}
.ss-bubble-talk:before {
  content: '\e166';
}
.ss-bubble-lined:before {
  content: '\e167';
}
.ss-bubble-dots-lined:before {
  content: '\e168';
}
.ss-bubble-talk-lined:before {
  content: '\e169';
}
.ss-bubble-blocked:before {
  content: '\e16c';
}
.ss-bubble-quote:before {
  content: '\e16d';
}
.ss-bubble-user:before {
  content: '\e16e';
}
.ss-bubble-check:before {
  content: '\e16f';
}
.ss-bubble-video-chat:before {
  content: '\e170';
}
.ss-bubble-link:before {
  content: '\e171';
}
.ss-bubble-locked:before {
  content: '\e172';
}
.ss-bubble-star:before {
  content: '\e173';
}
.ss-bubble-heart:before {
  content: '\e174';
}
.ss-bubble-paperclip:before {
  content: '\e175';
}
.ss-bubble-cancel:before {
  content: '\e176';
}
.ss-bubble-plus:before {
  content: '\e177';
}
.ss-bubble-minus:before {
  content: '\e178';
}
.ss-bubble-notification:before {
  content: '\e179';
}
.ss-user:before {
  content: '\e185';
}
.ss-users:before {
  content: '\e186';
}
.ss-user-plus:before {
  content: '\e187';
}
.ss-user-plus-alt:before {
  content: '\e188';
}
.ss-user-minus:before {
  content: '\e189';
}
.ss-user-minus-alt:before {
  content: '\e18a';
}
.ss-user-cancel:before {
  content: '\e18b';
}
.ss-user-block:before {
  content: '\e18c';
}
.ss-users-duo:before {
  content: '\e18d';
}
.ss-vcard:before {
  content: '\e19c';
}
.ss-quotes-left:before {
  content: '\e19f';
}
.ss-quotes-right:before {
  content: '\e1a0';
}
.ss-binoculars:before {
  content: '\e1b4';
}
.ss-expand:before {
  content: '\e1bf';
}
.ss-contract:before {
  content: '\e1c0';
}
.ss-expand-alt:before {
  content: '\e1c8';
}
.ss-contract-alt:before {
  content: '\e1c9';
}
.ss-key:before {
  content: '\e1cd';
}
.ss-key-alt:before {
  content: '\e1ce';
}
.ss-keyhole:before {
  content: '\e1cf';
}
.ss-lock:before {
  content: '\e1d3';
}
.ss-unlocked:before {
  content: '\e1d4';
}
.ss-wrench2:before {
  content: '\e1d7';
}
.ss-wrench-lined:before {
  content: '\e1d8';
}
.ss-wrench3:before {
  content: '\e1da';
}
.ss-settings:before {
  content: '\e1db';
}
.ss-equalizer:before {
  content: '\e1dc';
}
.ss-cog:before {
  content: '\e1e1';
}
.ss-hammer:before {
  content: '\e1e8';
}
.ss-screwdriver:before {
  content: '\e1eb';
}
.ss-wand:before {
  content: '\e1ec';
}
.ss-wand-alt:before {
  content: '\e1ed';
}
.ss-health:before {
  content: '\e1ee';
}
.ss-aid:before {
  content: '\e1ef';
}
.ss-bug:before {
  content: '\e1f2';
}
.ss-inject:before {
  content: '\e1f4';
}
.ss-cone:before {
  content: '\e1f6';
}
.ss-pie:before {
  content: '\e1f8';
}
.ss-pie-lined:before {
  content: '\e1fb';
}
.ss-stats-up:before {
  content: '\e207';
}
.ss-stats-down:before {
  content: '\e208';
}
.ss-cake:before {
  content: '\e20f';
}
.ss-gift-lined:before {
  content: '\e210';
}
.ss-gift:before {
  content: '\e211';
}
.ss-balloon:before {
  content: '\e212';
}
.ss-rating:before {
  content: '\e213';
}
.ss-rating-2:before {
  content: '\e214';
}
.ss-rating-3:before {
  content: '\e215';
}
.ss-podium:before {
  content: '\e216';
}
.ss-medal:before {
  content: '\e218';
}
.ss-medal-1:before {
  content: '\e219';
}
.ss-medal-2:before {
  content: '\e21a';
}
.ss-medal-3:before {
  content: '\e21b';
}
.ss-crown:before {
  content: '\e21c';
}
.ss-trophy:before {
  content: '\e21e';
}
.ss-trophy-star:before {
  content: '\e21f';
}
.ss-glass:before {
  content: '\e223';
}
.ss-bottle:before {
  content: '\e224';
}
.ss-food:before {
  content: '\e228';
}
.ss-hamburger:before {
  content: '\e229';
}
.ss-apple-fruit:before {
  content: '\e22e';
}
.ss-paw:before {
  content: '\e231';
}
.ss-steps:before {
  content: '\e232';
}
.ss-dashboard:before {
  content: '\e23a';
}
.ss-balance:before {
  content: '\e23c';
}
.ss-bomb:before {
  content: '\e23d';
}
.ss-fire:before {
  content: '\e23e';
}
.ss-lab:before {
  content: '\e240';
}
.ss-atom:before {
  content: '\e241';
}
.ss-magnet:before {
  content: '\e244';
}
.ss-dumbbell:before {
  content: '\e247';
}
.ss-skull:before {
  content: '\e248';
}
.ss-skull-lined:before {
  content: '\e24a';
}
.ss-remove:before {
  content: '\e251';
}
.ss-briefcase:before {
  content: '\e257';
}
.ss-briefcase-alt-full:before {
  content: '\e258';
}
.ss-briefcase-alt:before {
  content: '\e259';
}
.ss-airplane-alt:before {
  content: '\e25b';
}
.ss-truck:before {
  content: '\e260';
}
.ss-bike:before {
  content: '\e261';
}
.ss-gun:before {
  content: '\e27c';
}
.ss-gun-ban:before {
  content: '\e27d';
}
.ss-soccer:before {
  content: '\e282';
}
.ss-football:before {
  content: '\e283';
}
.ss-baseball:before {
  content: '\e284';
}
.ss-basketball:before {
  content: '\e285';
}
.ss-hockey:before {
  content: '\e287';
}
.ss-racing:before {
  content: '\e288';
}
.ss-eightball:before {
  content: '\e289';
}
.ss-bowlingball:before {
  content: '\e28a';
}
.ss-bowling3:before {
  content: '\e28b';
}
.ss-lightning:before {
  content: '\e28e';
}
.ss-switch:before {
  content: '\e290';
}
.ss-cord:before {
  content: '\e292';
}
.ss-socket:before {
  content: '\e293';
}
.ss-clipboard:before {
  content: '\e297';
}
.ss-clipboard-alt:before {
  content: '\e298';
}
.ss-list-alt:before {
  content: '\e299';
}
.ss-list:before {
  content: '\e29a';
}
.ss-numbered-list:before {
  content: '\e29c';
}
.ss-grid-alt:before {
  content: '\e2a0';
}
.ss-grid-circle-alt:before {
  content: '\e2a1';
}
.ss-grid-4up:before {
  content: '\e2a4';
}
.ss-flowtree:before {
  content: '\e2a7';
}
.ss-flowtree-lined:before {
  content: '\e2a8';
}
.ss-menu-overflow-alt-vertical:before {
  content: '\e2a9';
}
.ss-menu-overflow-vertical:before {
  content: '\e2aa';
}
.ss-circle-small:before {
  content: '\e2ab';
}
.ss-link:before {
  content: '\e2cd';
}
.ss-anchor:before {
  content: '\e2d0';
}
.ss-attachment-alt:before {
  content: '\e2d8';
}
.ss-bookmark:before {
  content: '\e2e3';
}
.ss-bookmark-lined:before {
  content: '\e2e4';
}
.ss-spotlight:before {
  content: '\e2e7';
}
.ss-starburst:before {
  content: '\e2e8';
}
.ss-snowflake:before {
  content: '\e2e9';
}
.ss-temperature:before {
  content: '\e2eb';
}
.ss-weather-lightning:before {
  content: '\e2ec';
}
.ss-weather-rain:before {
  content: '\e2ed';
}
.ss-weather-snow:before {
  content: '\e2ee';
}
.ss-windy:before {
  content: '\e2ef';
}
.ss-umbrella:before {
  content: '\e2f1';
}
.ss-contrast:before {
  content: '\e2f8';
}
.ss-moon:before {
  content: '\e2f9';
}
.ss-bed:before {
  content: '\e2fa';
}
.ss-heart:before {
  content: '\e307';
}
.ss-heart-lined:before {
  content: '\e308';
}
.ss-heart-broken:before {
  content: '\e309';
}
.ss-like:before {
  content: '\e310';
}
.ss-dislike:before {
  content: '\e312';
}
.ss-people:before {
  content: '\e317';
}
.ss-man:before {
  content: '\e318';
}
.ss-woman:before {
  content: '\e31a';
}
.ss-peace:before {
  content: '\e31c';
}
.ss-yin-yang:before {
  content: '\e31d';
}
.ss-happy-lined:before {
  content: '\e31e';
}
.ss-happy:before {
  content: '\e31f';
}
.ss-cursor:before {
  content: '\e338';
}
.ss-cursor-alt:before {
  content: '\e339';
}
.ss-stack-empty:before {
  content: '\e340';
}
.ss-stack-star:before {
  content: '\e343';
}
.ss-stack-cancel:before {
  content: '\e347';
}
.ss-stack-checkmark:before {
  content: '\e348';
}
.ss-stack-list:before {
  content: '\e349';
}
.ss-stack-user:before {
  content: '\e34e';
}
.ss-move:before {
  content: '\e352';
}
.ss-resize:before {
  content: '\e353';
}
.ss-resize-alt:before {
  content: '\e354';
}
.ss-notification-lined:before {
  content: '\e357';
}
.ss-notification:before {
  content: '\e358';
}
.ss-plus-circle-lined:before {
  content: '\e35e';
}
.ss-plus-circle:before {
  content: '\e35f';
}
.ss-minus-circle-lined:before {
  content: '\e360';
}
.ss-minus-circle:before {
  content: '\e361';
}
.ss-info-lined:before {
  content: '\e362';
}
.ss-info:before {
  content: '\e363';
}
.ss-blocked:before {
  content: '\e364';
}
.ss-cancel:before {
  content: '\e369';
}
.ss-spam:before {
  content: '\e36a';
}
.ss-close:before {
  content: '\e36b';
}
.ss-close-alt:before {
  content: '\e36c';
}
.ss-checkmark-circle-lined:before {
  content: '\e367';
}
.ss-checkmark-circle:before {
  content: '\ee71';
}
.ss-checkbox-checked2:before {
  content: '\ee69';
}
.ss-checkbox-checked:before {
  content: '\ee61';
}
.ss-checkmark-light:before {
  content: '\ed6e';
}
.ss-checkmark:before {
  content: '\e370';
}
.ss-checkmark-lined:before {
  content: '\e371';
}
.ss-minus:before {
  content: '\e375';
}
.ss-plus:before {
  content: '\e376';
}
.ss-enter:before {
  content: '\e37b';
}
.ss-exit:before {
  content: '\e37c';
}
.ss-play-circled:before {
  content: '\e380';
}
.ss-pause-circled:before {
  content: '\e381';
}
.ss-stop-circled:before {
  content: '\e382';
}
.ss-play:before {
  content: '\e385';
}
.ss-pause:before {
  content: '\e386';
}
.ss-stop:before {
  content: '\e387';
}
.ss-backward:before {
  content: '\e388';
}
.ss-forward:before {
  content: '\e389';
}
.ss-first:before {
  content: '\e38a';
}
.ss-last:before {
  content: '\e38b';
}
.ss-previous:before {
  content: '\e38c';
}
.ss-next:before {
  content: '\e38d';
}
.ss-volume:before {
  content: '\e390';
}
.ss-volume-mute:before {
  content: '\e393';
}
.ss-volume-off:before {
  content: '\e3a4';
}
.ss-repeat:before {
  content: '\e3a9';
}
.ss-shuffle:before {
  content: '\e3ab';
}
.ss-caret-up-bold:before {
  content: '\e3b0';
}
.ss-caret-right-bold:before {
  content: '\e3b1';
}
.ss-caret-down-bold:before {
  content: '\e3b2';
}
.ss-caret-left-bold:before {
  content: '\e3b3';
}
.ss-pip-up:before {
  content: '\e3b4';
}
.ss-pip-right:before {
  content: '\e3b5';
}
.ss-pip-down:before {
  content: '\e3b6';
}
.ss-pip-left:before {
  content: '\e3b7';
}
.ss-key-keyboard:before {
  content: '\e427';
}
.ss-command:before {
  content: '\e42d';
}
.ss-radio-checked-lined:before {
  content: '\e438';
}
.ss-radio-unchecked-lined:before {
  content: '\e439';
}
.ss-circle:before {
  content: '\e43b';
}
.ss-crop:before {
  content: '\e43c';
}
.ss-rulers:before {
  content: '\e43f';
}
.ss-filter:before {
  content: '\e445';
}
.ss-filter-lined:before {
  content: '\e446';
}
.ss-font-size:before {
  content: '\e453';
}
.ss-code:before {
  content: '\e480';
}
.ss-console:before {
  content: '\e481';
}
.ss-share-android:before {
  content: '\e48c';
}
.ss-google-drive:before {
  content: '\e497';
}
.ss-instagram:before {
  content: '\e49c';
}
.ss-twitter:before {
  content: '\e49d';
}
.ss-feed:before {
  content: '\e4a0';
}
.ss-youtube2:before {
  content: '\e4a3';
}
.ss-dribbble:before {
  content: '\e4af';
}
.ss-github-alt:before {
  content: '\e4b9';
}
.ss-github2:before {
  content: '\e4ba';
}
.ss-git:before {
  content: '\e4bb';
}
.ss-apple:before {
  content: '\e4c6';
}
.ss-android:before {
  content: '\e4c8';
}
.ss-linkedin-alt:before {
  content: '\e4cf';
}
.ss-file-zip:before {
  content: '\e4e6';
}
.ss-file-powerpoint:before {
  content: '\e4e7';
}
.ss-file-xml:before {
  content: '\e4e8';
}
.ss-file-css:before {
  content: '\e4e9';
}
.ss-html5:before {
  content: '\e4ea';
}
.ss-html5-lined:before {
  content: '\e4eb';
}
.ss-chrome:before {
  content: '\e4ed';
}
.ss-firefox:before {
  content: '\e4ee';
}
.ss-ie:before {
  content: '\e4ef';
}
.ss-safari:before {
  content: '\e4f1';
}
