html,
body {
  background-color: #f9fafb;
  min-height: 100vh;
}

body,
input,
textarea,
select,
button,
legend,
p {
  -webkit-font-smoothing: antialiased;
  color: #242a30;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

a,
.link {
  color: #0a2b14;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}
